import React, { ReactElement } from "react"
import { Dialog } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import { UpdateRoutingFlowModal } from "../shared/UpdateRoutingFlowModal"
import { useStartJourneyChangeRoutingInfo } from "../shared/useQueryRoutingInfo"
import { useStartRouting } from "../shared/useQueryStartRouting"
import {
  getHasJourneyChangeModalTriggeredThisSession,
  updateHasJourneyChangeModalTriggeredThisSession
} from "components/views/modal/utils/localStorageUtils"
import { useJourneyChange } from "../hooks/useJourneyChange"
import { CarrotPlansPaths } from "utils/Paths"

export function JourneyChangeDialogContainer({
  origin,
  setToggleModalFromCta
}: {
  origin: "email" | "cta"
  setToggleModalFromCta?: (open: boolean) => void
}): ReactElement {
  const { shouldShowEngagedJourneyDisplay, journeyChangeDataIsLoaded } = useJourneyChange()
  const emailLink = origin === "email"
  const clickedLink = origin === "cta"
  const [openJourneyChangeModal, setOpenJourneyChangeModal] = React.useState(false)

  function handleClose(): void {
    setOpenJourneyChangeModal(false)
    if (clickedLink) {
      setToggleModalFromCta(false)
    }
    if (emailLink) {
      updateHasJourneyChangeModalTriggeredThisSession(true)
    }
  }

  React.useEffect(() => {
    if (emailLink && journeyChangeDataIsLoaded) {
      const journeyChangeDialogHasTriggered = getHasJourneyChangeModalTriggeredThisSession()
      const shouldRender = !journeyChangeDialogHasTriggered && shouldShowEngagedJourneyDisplay
      setOpenJourneyChangeModal(shouldRender)
    }
    if (clickedLink) {
      setOpenJourneyChangeModal(true)
    }
  }, [
    clickedLink,
    emailLink,
    journeyChangeDataIsLoaded,
    openJourneyChangeModal,
    setOpenJourneyChangeModal,
    shouldShowEngagedJourneyDisplay
  ])

  if (!openJourneyChangeModal || !journeyChangeDataIsLoaded) {
    return null
  }

  return <JourneyChangeDialog handleClose={handleClose} />
}

export function JourneyChangeDialog({ handleClose }: { handleClose: () => void }): ReactElement {
  const intl = useIntl()
  const header = intl.formatMessage({ defaultMessage: "We've got a few questions..." })
  const { startRouting } = useStartRouting(useStartJourneyChangeRoutingInfo(), CarrotPlansPaths.JOURNEY_CHANGE)

  return (
    <Dialog aria-labelledby="journey-change-modal" scroll="body" open>
      <UpdateRoutingFlowModal header={header} clickGoBack={() => handleClose()} clickUpdateMyAnswers={startRouting} />
    </Dialog>
  )
}
