import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Progress, Stack, Theme, Typography, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"
import { BodyWithSidebarLayout } from "../../features/global-ui/page-layout"
import { ICalendlyLink } from "../../contentful-types"
import { TalkToCarrotExpertsSidebar } from "./TalkToCarrotExpertsSidebar"
import { mapContentToExpertBio } from "components/read/shared/ExpertBioMapper"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { useSelector } from "react-redux"
import { getEmployeeId } from "../../reducers/userInfo"
import { CALENDLY_PRIMARY_COLOR } from "../../lib/contentful/utils/mappingUtils"
import { getHeap } from "../../utils/heap"
import { useGetSpecialistChatMetadata } from "./hooks/useGetSpecialistChatMetadata"
import { TalkToCarrotCalendlyError } from "./TalkToCarrotCalendlyError"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useTrackExperimentEvent } from "../views/register/hooks/useExperiments"

const FAILSAFE_MINIMUM_LOAD_TIME_MS = 10000
const FAILSAFE_LOAD_TIME_SCALING_FACTOR = 5

const LoadingSpinner = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      inlineSize: "calc(100% - 10rem)",
      marginBlockStart: "50%",
      marginInlineStart: "5rem",
      padding: "2rem"
    }}
  >
    <Progress size="3rem" />
  </Box>
)

export const TalkToCarrotScheduleSpecialistChat = ({
  calendlyLink
}: {
  calendlyLink: ICalendlyLink
}): React.ReactElement => {
  const theme = useTheme()
  const { calendlyErrorDetectionThreshold } = useFlags()
  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const employeeId = useSelector(getEmployeeId)
  const { country, specialistType, language } = useGetSpecialistChatMetadata()
  const { trackExperimentEvent } = useTrackExperimentEvent()
  const [isCalendlyWidgetLoading, setIsCalendlyWidgetLoading] = useState<boolean>(true)
  const [isEventTypeViewed, setIsEventTypedViewed] = useState<boolean>(false)
  const isCalendlyWidgetError = !isCalendlyWidgetLoading && !isEventTypeViewed

  const {
    fields: { url, expertBios },
    sys: { id }
  } = calendlyLink

  const minimumLoadTimeMs = calendlyErrorDetectionThreshold?.minimumLoadTimeMs ?? FAILSAFE_MINIMUM_LOAD_TIME_MS
  const pageLoadTimeScalingFactor =
    calendlyErrorDetectionThreshold?.pageLoadTimeScalingFactor ?? FAILSAFE_LOAD_TIME_SCALING_FACTOR

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (calendlyLink) {
        getHeap().track("Talk to carrot specialist chat scheduled", {
          "Calendly chat id": id,
          "Specialist chat country": country,
          "Specialist chat type": specialistType,
          "Specialist chat language": language
        })
        trackExperimentEvent("talk-to-carrot-expert-chat-scheduled")
      }
    },
    onEventTypeViewed: () => {
      setIsCalendlyWidgetLoading(false)
      setIsEventTypedViewed(true)
    }
  })

  useEffect(() => {
    const entry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming
    const loadTime = entry.loadEventEnd - entry.domContentLoadedEventStart
    const timeoutId = setTimeout(
      () => {
        setIsCalendlyWidgetLoading(false)
      },
      loadTime > minimumLoadTimeMs ? loadTime * pageLoadTimeScalingFactor : minimumLoadTimeMs
    )

    return () => {
      clearTimeout(timeoutId)
    }
  }, [minimumLoadTimeMs, pageLoadTimeScalingFactor])

  const expertsSidebar =
    expertBios?.length > 0 ? <TalkToCarrotExpertsSidebar expertBios={expertBios.map(mapContentToExpertBio)} /> : null

  return isCalendlyWidgetError ? (
    <TalkToCarrotCalendlyError />
  ) : (
    <BodyWithSidebarLayout sidebar={expertsSidebar}>
      <Stack gap={theme.spacing(theme.tokens.spacing.md)}>
        <Typography variant="h1" sx={{ display: "none" }}>
          <FormattedMessage defaultMessage="Schedule a specialist chat" />
        </Typography>
        <Typography variant="h3" component="h2" aria-hidden>
          <FormattedMessage defaultMessage="Find a time that works best for you" />
        </Typography>
        <InlineWidget
          url={url}
          utm={{ utmCampaign: employeeId }}
          pageSettings={{
            primaryColor: CALENDLY_PRIMARY_COLOR
          }}
          styles={{
            border: `${theme.tokens.borderWidth.md} solid ${theme.palette.border.light}`,
            padding: `${theme.spacing(theme.tokens.spacing.xxxs)}`,
            borderRadius: `${theme.borderRadius(theme.tokens.borderRadius.md)}px`,
            height: isMobileView ? "50vh" : "60vh",
            scrollBehavior: "auto"
          }}
          LoadingSpinner={LoadingSpinner}
        />
      </Stack>
    </BodyWithSidebarLayout>
  )
}
