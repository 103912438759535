import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import React from "react"
import { Container, FlexContainer } from "@carrotfertility/carotene"
import { Typography, Box } from "@carrotfertility/carotene-core"
import { BenefitEnrollmentModalContinueButton } from "../../../shared"
import { IlloBlocks } from "../../../../views/atoms/Illustrations"
import { FormattedMessage } from "react-intl"

export function PartnerConnectSuccess(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.PARTNER_PROCESS_CONNECT_INSURANCE_SUCCESS, null)

  function onClickContinue(): void {
    setNextStep(Steps.PARTNER_PROCESS_CONNECT_INSURANCE_SUCCESS, "")
  }

  return (
    <div>
      <Box height={(theme) => theme.tokens.spacing.xxxl} />
      <FlexContainer justifyContent="center" padding="none" stack="medium">
        <IlloBlocks />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="Connection was successful." />
      </Typography>
      <Container padding="none" stack="huge">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="We’ll take it from here and will let you know if we have any more questions about their insurance." />
        </Typography>
      </Container>
      <BenefitEnrollmentModalContinueButton onClick={onClickContinue} />
    </div>
  )
}

export function MemberConnectSuccess(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.MEMBER_PROCESS_CONNECT_INSURANCE_SUCCESS, null)

  function onClickContinue(): void {
    setNextStep(Steps.MEMBER_PROCESS_CONNECT_INSURANCE_SUCCESS, "")
  }

  return (
    <div>
      <Box height={(theme) => theme.tokens.spacing.xxxl} />
      <FlexContainer justifyContent="center" padding="none" stack="medium">
        <IlloBlocks />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="Connection was successful." />
      </Typography>
      <Container padding="none" stack="huge">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="We'll take it from here and will let you know if we have any more questions about your insurance." />
        </Typography>
      </Container>
      <BenefitEnrollmentModalContinueButton onClick={onClickContinue} />
    </div>
  )
}
