import React, { FC } from "react"
import CardStates, { CardState } from "utils/CardStates"
import { CarrotCardEligibleModule } from "components/views/molecules/carrotcardmodules/CarrotCardEligibleModule"
import { CarrotCardLockedModule } from "components/views/molecules/carrotcardmodules/CarrotCardLockedModule"
import { CarrotCardShippedModule } from "components/views/molecules/carrotcardmodules/CarrotCardShippedModule"
import { useSelector } from "react-redux"
import { getIsUsa } from "reducers/userInfo"
import { CarrotCardDeductibleNotMetLockedModule } from "./carrotcardmodules/CarrotCardDeductibleNotMetLockedModule"
import { CarrotCardEligibleWithDeductibleModule } from "./carrotcardmodules/CarrotCardEligibleWithDeductibleModule"
import { CarrotCardNeedsEnrollmentModule } from "./carrotcardmodules/CarrotCardNeedsEnrollmentModule"
import { getReimbursementDetails, getReimbursementDetailsStatus } from "../../../reducers/reimbursementDetails"
import { CarrotCardLoadingModule } from "./carrotcardmodules/CarrotCardLoadingModule"
import { CarrotCardRestrictionPeriodModule } from "./carrotcardmodules/CarrotCardRestrictionPeriodModule"
import { CarrotCardRequestedModule } from "./carrotcardmodules/CarrotCardRequestedModule"
import { CarrotCardActiveModule } from "./carrotcardmodules/CarrotCardActiveModule"

const carrotCardModules: Partial<Record<CardState, FC<CarrotCardModuleSharedProps>>> = {
  [CardStates.LOCKED]: CarrotCardLockedModule,
  [CardStates.ACTIVE]: CarrotCardActiveModule,
  [CardStates.PENDING]: CarrotCardRequestedModule,
  [CardStates.SHIPPED]: CarrotCardShippedModule,
  [CardStates.ELIGIBLE]: CarrotCardEligibleModule,
  [CardStates.ELIGIBLE_WITH_DEDUCTIBLE_NOT_MET]: CarrotCardEligibleWithDeductibleModule,
  [CardStates.DEDUCTIBLE_NOT_MET]: CarrotCardDeductibleNotMetLockedModule,
  [CardStates.NEEDS_ENROLLMENT]: CarrotCardNeedsEnrollmentModule,
  [CardStates.INELIGIBLE]: null,
  [CardStates.RESTRICTION_PERIOD]: CarrotCardRestrictionPeriodModule,
  [CardStates.MANUAL_LOCK]: CarrotCardLockedModule
}

type CarrotCardModuleContainerProps = {
  onlyShowRequestModule?: boolean
}
export type CarrotCardModuleSharedProps = {
  isVirtualCard: boolean
}

/**
 * A connected factory component that returns the module for a given card state
 *
 * This component returns null if no view corresponds to the given cardState
 * or the member is part of a carrot lite company
 */
export const CarrotCardModuleContainer: FC<CarrotCardModuleContainerProps> = () => {
  const reimbursementDetails = useSelector(getReimbursementDetails)
  const reimbursementDetailsRequestStatus = useSelector(getReimbursementDetailsStatus)
  const isUsa = useSelector(getIsUsa)

  // This check ensures that the dependencies we use to determine deductibleRemaining have been loaded prior to rendering.
  // The implication is that for CMD/EMD members, there could be a delay in rendering the module until we have a
  // deductible snapshot, which can sometimes take 5-30 seconds, but is necessary to prevent members from requesting
  // a CC before they have met their deductible
  if (!reimbursementDetails) {
    return null
  }

  const carrotCardState: CardStates = reimbursementDetails?.carrotCardState

  const ModuleComponent =
    reimbursementDetailsRequestStatus !== "PENDING" ? carrotCardModules[carrotCardState] : CarrotCardLoadingModule

  return ModuleComponent ? <ModuleComponent isVirtualCard={!isUsa} /> : null
}
