import { Steps } from "../../steps"

// prettier-ignore
export default [
  { forStep: Steps.PROCESS_ENROLLMENT_TYPE,                       memberSelects: "",                             goto: Steps.FIRST_TIME_OR_RE_ENROLL},
  { forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                       memberSelects: Steps.RE_ENROLL_INTRO_INFORMATIONAL, goto: Steps.RE_ENROLL_INTRO_INFORMATIONAL},
  { forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                       memberSelects: Steps.INTRO_INFORMATIONAL,      goto: Steps.INTRO_INFORMATIONAL},
  { forStep: Steps.RE_ENROLL_INTRO_INFORMATIONAL,                 memberSelects: "",                             goto: Steps.INSURANCE_DISCLAIMER},
  { forStep: Steps.INTRO_INFORMATIONAL,                           memberSelects: "",                             goto: Steps.INSURANCE_DISCLAIMER },
  { forStep: Steps.INTRO_INFORMATIONAL,                           memberSelects: "SKIP_THIS_FLOW",               goto: Steps.CONNECT_INSURANCE_NOT_REQUIRED},
  { forStep: Steps.INSURANCE_DISCLAIMER,                          memberSelects: "",                             goto: Steps.ARE_YOU_ENROLLED_IN_MEDICARE},
  { forStep: Steps.INSURANCE_DISCLAIMER,                          memberSelects: "SKIP_MEDICARE",                goto: Steps.MEMBER_PLAN_SOURCE },
  { forStep: Steps.ARE_YOU_ENROLLED_IN_MEDICARE,                  memberSelects: "YES",                          goto: Steps.MEDICARE_ID },
  { forStep: Steps.ARE_YOU_ENROLLED_IN_MEDICARE,                  memberSelects: "NO",                           goto: Steps.MEMBER_PLAN_SOURCE },
  { forStep: Steps.MEDICARE_ID,                                   memberSelects: "",                             goto: Steps.MEMBER_PLAN_SOURCE },
  { forStep: Steps.MEMBER_PLAN_SOURCE,                            memberSelects: "EMPLOYER",                     goto: Steps.ARE_YOU_LEGALLY_MARRIED },
  { forStep: Steps.MEMBER_PLAN_SOURCE,                            memberSelects: "PARENT",                       goto: Steps.ARE_YOU_LEGALLY_MARRIED },
  { forStep: Steps.MEMBER_PLAN_SOURCE,                            memberSelects: "OTHER",                        goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.MEMBER_PLAN_SOURCE,                            memberSelects: "NOT_INSURED",                  goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.MEMBER_PLAN_SOURCE,                            memberSelects: "PARTNER",                      goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.MEMBER_PLAN_SOURCE,                            memberSelects: "IM_NOT_SURE",                  goto: Steps.TALK_TO_CARROT },
  { forStep: Steps.ARE_YOU_LEGALLY_MARRIED,                       memberSelects: "true",                         goto: Steps.PARTNER_PLAN_SOURCE_ACA },
  { forStep: Steps.ARE_YOU_LEGALLY_MARRIED,                       memberSelects: "false",                        goto: Steps.PARTNER_TAX_DEPENDENT },
  { forStep: Steps.PARTNER_TAX_DEPENDENT,                         memberSelects: "true",                         goto: Steps.PARTNER_PLAN_SOURCE_ACA },
  { forStep: Steps.PARTNER_TAX_DEPENDENT,                         memberSelects: "false",                        goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.PARTNER_TAX_DEPENDENT,                         memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT },
  { forStep: Steps.PARTNER_PLAN_SOURCE_ACA,                       memberSelects: "EMPLOYER",                     goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.PARTNER_PLAN_SOURCE_ACA,                       memberSelects: "PARENT",                       goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.PARTNER_PLAN_SOURCE_ACA,                       memberSelects: "MEMBER",                       goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.PARTNER_PLAN_SOURCE_ACA,                       memberSelects: "OTHER",                        goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.PARTNER_PLAN_SOURCE_ACA,                       memberSelects: "NOT_INSURED",                  goto: Steps.PROCESS_MEMBER_ENROLLMENT },
  { forStep: Steps.PARTNER_PLAN_SOURCE_ACA,                       memberSelects: "IM_NOT_SURE",                  goto: Steps.TALK_TO_CARROT },
  { forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                     memberSelects: "NO_DEDUCTIBLE",                goto: Steps.CONNECT_INSURANCE_NOT_REQUIRED },
  { forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                     memberSelects: "ALREADY_ENROLLED",             goto: Steps.CONNECT_INSURANCE_NOT_REQUIRED },
  { forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                     memberSelects: "HRA_INELIGIBLE",               goto: Steps.HRA_INELIGIBLE },
  { forStep: Steps.HRA_INELIGIBLE,                                memberSelects: "NO_DEDUCTIBLE",                goto: Steps.PROCESS_MEMBER_ENROLLMENT }
]
