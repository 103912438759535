import React from "react"
import {
  Avatar,
  DownIcon,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme
} from "@carrotfertility/carotene-core"
import { useHistory } from "react-router"
import { PiiText } from "services/tracking"
import { useIntl } from "react-intl"

type DropdownItem = {
  condition?: boolean
}

type LinkDropdownItem = DropdownItem & {
  id: string
  label: string
  href: string
}

type ButtonDropdownItem = DropdownItem & {
  id: string
  label: string
  onClick: (handleClose: () => void) => unknown
}

export interface UserDropdownProps {
  name: string
  navigationLinks: Array<ButtonDropdownItem | LinkDropdownItem>
}

export function UserDropdown({ name, navigationLinks }: UserDropdownProps) {
  const intl = useIntl()
  const theme = useTheme()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigationItemToMenuItem = React.useCallback(
    ({ condition, href, onClick, label, ...otherProps }: ButtonDropdownItem & LinkDropdownItem) => {
      return typeof condition === "undefined" || condition ? (
        <MenuItem
          key={otherProps.id}
          {...otherProps}
          onClick={() => {
            if (href) {
              handleClose()
              history.push(href)
            } else {
              onClick(handleClose)
            }
          }}
        >
          {label}
        </MenuItem>
      ) : null
    },
    [history]
  )
  return (
    <>
      <IconButton
        id="user-dropdown-button"
        aria-label={intl.formatMessage({
          defaultMessage: "Account settings and profile information"
        })}
        aria-controls={open ? "user-dropdown" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={(theme) => ({
          borderRadius: theme.tokens.borderRadius.sm,
          color: "unset",
          padding: theme.spacing(theme.tokens.spacing.md)
        })}
      >
        <Stack alignItems="center" direction="row" spacing={theme.tokens.spacing.xs}>
          <Avatar
            alt=""
            src="/images/icn-user.png"
            srcSet="/images/icn-user-2x.png"
            sx={{ inlineSize: 32, blockSize: 32 }}
          />
          <Typography
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.up("lg")]: {
                display: "inherit"
              }
            })}
            component={PiiText}
            fontWeight="bold"
          >
            {name}
          </Typography>
          <DownIcon
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.up("lg")]: {
                transition: "transform 100ms",
                transform: `rotate(${open ? "180deg" : "0deg"})`,
                display: "unset"
              }
            })}
            color="primary"
          />
        </Stack>
      </IconButton>
      <Menu
        id="user-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "user-dropdown-button"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {navigationLinks.map(navigationItemToMenuItem).filter(Boolean)}
      </Menu>
    </>
  )
}
