import React, { FC, ReactNode } from "react"
import styled, { css } from "styled-components"
import { Font } from "types/font"

type BodyProps = {
  color?: string
  display?: string
  font?: Font
  align?: string
  additionalClasses?: string
  weight?: string
  children: ReactNode
  opacity?: number
  cursor?: string
}

const sharedBodyCss = css`
  margin-block-start: 0;
  margin-block-end: 0;
  font-style: normal;
`

type BodyBaseProps = {
  fontSize: number
  lineHeight: number
} & BodyProps

const BodyBase: FC<BodyBaseProps> = ({
  fontSize,
  lineHeight,
  children,
  color = "black",
  display = "db",
  font = "gotham",
  align = "",
  weight = "normal",
  additionalClasses = "",
  opacity = 1.0,
  cursor = "unset",
  ...otherProps
}: BodyBaseProps) => {
  const className = `
    ${weight} ${color} ${display} ${font} ${align} ${additionalClasses}
  `

  return (
    <StyledBodyBase
      style={{ fontSize: fontSize, lineHeight: lineHeight + "%", cursor: cursor, opacity: opacity }}
      className={className}
      {...otherProps}
    >
      {children}
    </StyledBodyBase>
  )
}

const StyledBodyBase = styled.p`
  ${sharedBodyCss};
`

const Body: FC<BodyProps> = ({ children, ...props }: BodyProps) => {
  return (
    <BodyBase fontSize={16} lineHeight={150} {...props}>
      {children}
    </BodyBase>
  )
}

const BodySmall: FC<BodyProps> = ({ children, ...props }) => {
  return (
    <BodyBase fontSize={14} lineHeight={150} {...props}>
      {children}
    </BodyBase>
  )
}

const BodyLarge: FC<BodyProps> = ({ font = "publico-headline", children, ...props }) => {
  return (
    <BodyBase fontSize={22} lineHeight={150} font={font} {...props}>
      {children}
    </BodyBase>
  )
}

const BodyLargeAlt: FC<BodyProps> = ({ font = "publico-headline", children, ...props }) => {
  return (
    <BodyBase fontSize={20} lineHeight={130} font={font} {...props}>
      {children}
    </BodyBase>
  )
}

const BodyAlt: FC<BodyProps> = ({ children, ...props }) => {
  return (
    <BodyBase fontSize={17} lineHeight={150} {...props}>
      {children}
    </BodyBase>
  )
}

export { BodyLarge, BodySmall, Body, BodyAlt, BodyLargeAlt }
