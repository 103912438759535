import React, { useState } from "react"
import { NETWORK_ID_TIER_MAPPING } from "../../utils/networkIdMapping"
import { ProviderTypeTags } from "./ProviderTypeTags"
import { tierForModalProps, TierInfoModal } from "./TierInfoModal"
import { FormattedMessage } from "react-intl"
import { Benefit } from "pages/provider-finder/types/Benefit"
import { Box, Chip, ChipProps, InfoIcon, Link, Stack } from "@carrotfertility/carotene-core"

function getChipStyleByNetworkTier(networkId: number): Partial<ChipProps> {
  switch (networkId) {
    case 5:
      return {
        sx: (theme) => ({
          color: theme.palette.text.primary,
          bgcolor: theme.palette.info.light
        })
      }
    case 6:
      return {
        color: "info"
      }
  }
  return {}
}

export function ProviderTypeTagsForExtendedNetworks({
  parentIds,
  providerTypeIds,
  networkIds,
  benefit
}: {
  parentIds: number[]
  providerTypeIds: number[]
  networkIds: number[]
  benefit: Benefit
}): JSX.Element {
  const [open, setOpen] = useState(false)
  const [tierForModal, setTierForModal] = useState<tierForModalProps>(null)
  const providerTierTags = React.useMemo(
    () =>
      networkIds
        .map((networkId, i) => {
          const providerTier = NETWORK_ID_TIER_MAPPING[networkId as keyof typeof NETWORK_ID_TIER_MAPPING]
          if (!providerTier) {
            return null
          }
          return (
            <Link
              component="button"
              key={i}
              onClick={() => {
                setOpen(true)
                setTierForModal(providerTier)
              }}
            >
              <Chip
                {...getChipStyleByNetworkTier(networkId)}
                size="small"
                key={i}
                label={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FormattedMessage {...providerTier.tierDisplayName} />
                    &nbsp;
                    <InfoIcon color="inherit" fontSize="inherit" />
                  </Box>
                }
              />
            </Link>
          )
        })
        .filter(Boolean),
    [networkIds]
  )

  return (
    <Box display="flex" flexDirection="row" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
      <ProviderTypeTags parentIds={parentIds} providerTypeIds={providerTypeIds} benefit={benefit} />
      <Stack
        direction="row"
        spacing="0"
        useFlexGap
        flexWrap="wrap"
        gap={(theme) => `${theme.spacing(theme.tokens.spacing.xs)} ${theme.spacing(theme.tokens.spacing.xxs)}`}
        sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.sm) })}
      >
        {providerTierTags}
      </Stack>
      <TierInfoModal open={open} onClose={() => setOpen(false)} tierForModal={tierForModal} benefit={benefit} />
    </Box>
  )
}
