import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import CardStates from "utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"
import { InternalIcon, Link } from "@carrotfertility/carotene-core"

const CarrotCardDeductibleNotMetLockedModule: FC<CarrotCardModuleSharedProps> = () => (
  <CarrotCardModuleTemplate
    cardState={CardStates.DEDUCTIBLE_NOT_MET}
    body={<FormattedMessage defaultMessage="Your card will be unlocked when you meet your deductible for this year." />}
    links={[
      <Link
        key="carrot-card-deductibles"
        href={"/your-benefit-guide/carrot-funds/deductibles"}
        target={"_blank"}
        endIcon={<InternalIcon fontSize="small" color="primary" />}
      >
        <FormattedMessage defaultMessage="Learn more about your deductible" />
      </Link>
    ]}
  />
)

export { CarrotCardDeductibleNotMetLockedModule }
