import { Link } from "react-router-dom"
import { Spacer } from "../views/atoms/Atoms"
// @ts-ignore
import React, { FC, useEffect, useState } from "react"
import { CircleImage } from "../views/atoms/images/CircleImage"
import { useSelector } from "react-redux"
import { getEmployeeId } from "../../reducers/userInfo"
import { PopupModal } from "react-calendly"
import { Box, InternalIcon, Typography } from "@carrotfertility/carotene-core"
import { CALENDLY_PRIMARY_COLOR } from "../../lib/contentful/utils/mappingUtils"

type TalkToCarrotButtonProps = {
  to: string
  iconName?: string
  label: string
  useModal?: boolean
  enabled?: boolean
  chatType?: string
}

type ButtonContentType = {
  iconName?: string
  label: string
  enabled?: boolean
}

type CalendlyWidgetProps = {
  url: string
  open: boolean
  setOpen: (value: boolean) => void
  chatType?: string
}

const CalendlyWidget = ({ url, open, setOpen, chatType }: CalendlyWidgetProps): JSX.Element => {
  const employeeId = useSelector(getEmployeeId)

  useEffect(() => {
    // @ts-expect-error TS7006
    const handleEsc = (event): void => {
      if (event.keyCode === 27) {
        setOpen(false)
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [setOpen])

  return (
    <PopupModal
      url={url}
      utm={{
        utmCampaign: employeeId,
        utmSource: chatType
      }}
      onModalClose={() => setOpen(false)}
      open={open}
      rootElement={document.getElementById("TTC-button-modal")}
      pageSettings={{
        primaryColor: CALENDLY_PRIMARY_COLOR
      }}
    />
  )
}

const TalkToCarrotButton: FC<TalkToCarrotButtonProps> = ({
  to,
  iconName,
  label,
  useModal = false,
  enabled = true,
  chatType = null
}: TalkToCarrotButtonProps) => {
  const [open, setOpen] = useState(false)

  const cursorType = enabled ? "pointer" : "not-allowed"
  const tabIndex = !enabled ? -1 : undefined

  return useModal ? (
    <Box id="TTC-button-modal">
      <Box
        component="button"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={(theme) => ({
          cursor: enabled ? "pointer" : "default",
          backgroundColor: theme.palette.background.paper,
          padding: `${theme.spacing(theme.tokens.spacing.md)} ${theme.spacing(theme.tokens.spacing.lg)}`,
          opacity: enabled ? 1 : 0.6,
          outlineOffset: theme.spacing(theme.tokens.spacing.xxxs)
        })}
        border={(theme) =>
          enabled
            ? `solid ${theme.tokens.borderWidth.md} ${theme.palette.border.light}`
            : `solid ${theme.tokens.borderWidth.md} ${theme.palette.secondary.light}`
        }
        borderRadius={(theme) => theme.tokens.borderRadius.md}
        onClick={() => setOpen(true)}
        tabIndex={tabIndex}
      >
        <ButtonContents label={label} iconName={iconName} enabled={enabled} />
      </Box>
      <CalendlyWidget url={to} chatType={chatType} open={open} setOpen={setOpen} />
    </Box>
  ) : (
    <Box
      id="TTC-button-modal"
      component="button"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      sx={(theme) => ({
        cursor: enabled ? "pointer" : "default",
        backgroundColor: theme.palette.background.paper,
        padding: `${theme.spacing(theme.tokens.spacing.md)} ${theme.spacing(theme.tokens.spacing.lg)}`,
        opacity: enabled ? 1 : 0.6,
        outlineOffset: theme.spacing(theme.tokens.spacing.xxxs)
      })}
      border={(theme) =>
        enabled
          ? `solid ${theme.tokens.borderWidth.md} ${theme.palette.border.light}`
          : `solid ${theme.tokens.borderWidth.md} ${theme.palette.secondary.light}`
      }
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      tabIndex={tabIndex}
    >
      <Link to={to} className={`no-underline ${cursorType} `}>
        <ButtonContents label={label} iconName={iconName} enabled={enabled} />
      </Link>
    </Box>
  )
}

const ButtonContents: FC<ButtonContentType> = ({ iconName, label }: ButtonContentType) => {
  return (
    <>
      {iconName && <CircleImage iconName={iconName.valueOf()} backgroundColor="icon-grey" />}
      <Spacer height={1} />
      <div className="w-100 flex justify-between">
        <Typography sx={{ textAlign: "start" }} variant="h5">
          {label}
        </Typography>
        <Spacer width={0.5} />
        <div style={{ minWidth: "10px" }}>
          <InternalIcon color="primary" />
        </div>
      </div>
    </>
  )
}

export { TalkToCarrotButton, ButtonContents }
