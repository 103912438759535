import { Link } from "react-router-dom"
import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import { useTheme } from "@carrotfertility/carotene-core"

type RRNavLinkProps = {
  to: string
  children: ReactNode
  width?: string
  noWrap?: boolean
  active?: boolean
  top?: boolean
}

const ActiveIndicator = styled.div`
  align-self: flex-start;
  margin-block-start: 0.55rem;
`

/**
 * A Link element intended for composition within a RoundedRectangle
 */
const RRNavLink: FC<RRNavLinkProps> = ({ to, children, width = "", noWrap = false, active = false, top = false }) => {
  const wrap = noWrap ? "nowrap" : ""
  const linkClasses = `w-100 black-70 no-underline ${wrap}`
  const wrapperClasses = `flex flex-wrap flex-grow-1 min-w150px f6 ${top ? "" : "mt2"} lh-copy ${width}`
  const theme = useTheme()

  return (
    <nav className={wrapperClasses}>
      <div
        style={{
          paddingRight: theme.spacing(theme.tokens.spacing.xxxs)
        }}
      >
        {active ? (
          <div className="flex items-center w-100">
            <ActiveIndicator className="dib nl3 w-8px h-2px" style={{ backgroundColor: theme.palette.text.primary }} />
            <div className={`ml2 fw5 black-80 dib w-100 ${wrap}`}>{children}</div>
          </div>
        ) : (
          <Link className={linkClasses} to={to}>
            {children}
          </Link>
        )}
      </div>
    </nav>
  )
}

export { RRNavLink }
