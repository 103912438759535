import useGetUserInfoConditionsSubset, {
  useCreateOverride
} from "services/user/hooks/useGetCurrentUserConditionsSubset"
import { UserConditionSubset } from "services/user/types/UserInfo"

export const useUserInfoMapper = () => {
  const { data } = useGetUserInfoConditionsSubset()

  const toggleBenefitData = useCreateOverride()

  const deepClone = (oldUserInfo: UserConditionSubset): UserConditionSubset => JSON.parse(JSON.stringify(oldUserInfo))

  const mapper = {
    carrot_card: {
      value: data?.isCarrotCardEligible,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.isCarrotCardEligible = !data.isCarrotCardEligible
        toggleBenefitData(overrideData)
      }
    },
    aca_applies: {
      value: data?.company.hasACA,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.company.hasACA = !data.company.hasACA
        toggleBenefitData(overrideData)
      }
    },
    has_legal_partner: {
      value: data?.memberHasLegalPartner,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.memberHasLegalPartner = !data.memberHasLegalPartner
        toggleBenefitData(overrideData)
      }
    },
    supports_deductible_plans: {
      value: data?.company.supportsDeductiblePlans,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.company.supportsDeductiblePlans = !data.company.supportsDeductiblePlans
        toggleBenefitData(overrideData)
      }
    },
    lock_down_and_carrot_plan_check: {
      value: !data?.isInAppLockdown,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.isInAppLockdown = !data.isInAppLockdown
        toggleBenefitData(overrideData)
      }
    },
    external_deductible: {
      value: data?.company.hasExternalDeductible,
      toggle: () => {
        const overrideData = deepClone(data)
        overrideData.company.hasExternalDeductible = !data.company.hasExternalDeductible
        toggleBenefitData(overrideData)
      }
    }
  }
  return mapper
}
