import actionTypes from "actions/actionTypes"
import { fetchReimbursementDetails } from "./reimbursementDetailsActions"
import { fetchUserInfo } from "./userActions"
import { createRequestAction } from "actions/shared"
import CarrotClient from "lib/carrot-api/index"
import Settings from "utils/CarrotConfig"
import {
  resetHasCrrModalTriggeredThisSession,
  resetHasJourneyChangeModalTriggeredThisSession
} from "components/views/modal/utils/localStorageUtils"

const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const fetchBenefitConfiguration = createRequestAction(
  "FETCH_BENEFIT_CONFIGURATION",
  client.getBenefitConfiguration.bind(client)
)

export const fetchReduxData = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.FETCH_IS_LOGGED_IN_REQUEST
    })
    await client.getIsUserLoggedIn()
    dispatch({
      type: actionTypes.FETCH_IS_LOGGED_IN_SUCCESS
    })

    const reduxDataActions = [fetchUserInfo, fetchBenefitConfiguration, fetchReimbursementDetails]
    reduxDataActions.forEach((action) => dispatch(action()))
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_IS_LOGGED_IN_FAILURE
    })
  }
}

export const signIn = () => async (dispatch) => {
  await dispatch({
    type: actionTypes.SIGN_IN
  })

  resetHasCrrModalTriggeredThisSession()
  resetHasJourneyChangeModalTriggeredThisSession()

  dispatch(fetchReduxData())
}

export const signOut = createRequestAction("FETCH_SIGN_OUT", client.logout.bind(client))

export const persistEmail = (email) => async (dispatch) => {
  await dispatch({
    type: actionTypes.PERSIST_EMAIL,
    payload: email
  })
}
