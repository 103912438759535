import React, { FC, useContext } from "react"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import { Container, Stack, Stepper, useTheme } from "@carrotfertility/carotene-core"

import { BackButton, CancelButton, ContinueButton } from "pages/request-carrot-card-flow/requestCardWrapper/buttons"

type RequestCardWrapperProps = {
  disableContinue?: string
  sectionNum?: number
  showBackButton?: boolean
  showCancelButton?: boolean
  goBackToStepName?: string
  showContinueButton?: boolean
  children: React.ReactNode
}

const RequestCardWrapper: FC<RequestCardWrapperProps> = ({
  children,
  disableContinue,
  goBackToStepName,
  sectionNum,
  showBackButton,
  showCancelButton,
  showContinueButton
}) => {
  const theme = useTheme()
  const { isUsaCompany } = useContext(RequestCardContext)
  const steps = (isUsaCompany ? 3 : 2) + 1

  return (
    <Container>
      {sectionNum < steps && (
        <Stepper
          sx={{ paddingBlockEnd: (theme) => theme.tokens.spacing.xl }}
          variant="progress"
          steps={steps}
          position="static"
          activeStep={sectionNum}
        />
      )}
      <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <div>{children}</div>
        {showBackButton && showContinueButton && (
          <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "space-between" }}>
            <BackButton goBackToStepName={goBackToStepName} />
            <ContinueButton disableContinue={disableContinue} />
          </Container>
        )}

        {showCancelButton && showContinueButton && (
          <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "space-between" }}>
            <CancelButton />
            <ContinueButton disableContinue={disableContinue} />
          </Container>
        )}

        {showContinueButton && !showBackButton && !showCancelButton && (
          <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "flex-end" }}>
            <ContinueButton disableContinue={disableContinue} />
          </Container>
        )}
      </Stack>
    </Container>
  )
}

RequestCardWrapper.defaultProps = {
  sectionNum: 1
}

export default RequestCardWrapper
