import React from "react"
import { filterParentIdByBenefitConfig, PARENT_ID_MAPPING } from "../../utils/parentIdMapping"
import { Benefit } from "pages/provider-finder/types/Benefit"
import { useIntl } from "react-intl"
import { Chip, Stack } from "@carrotfertility/carotene-core"

export function ProviderTypeTags({
  parentIds,
  providerTypeIds,
  benefit
}: {
  parentIds: number[]
  providerTypeIds: number[]
  benefit: Benefit
}): JSX.Element {
  const intl = useIntl()
  const tags = parentIds
    .filter(filterParentIdByBenefitConfig(benefit))
    .map((parentId) => {
      if (PARENT_ID_MAPPING[parentId].childrenProviderTypeIds) {
        return providerTypeIds
          .filter((providerTypeId) => providerTypeId in PARENT_ID_MAPPING[parentId].childrenProviderTypeIds)
          .map((providerTypeId) => PARENT_ID_MAPPING[parentId].childrenProviderTypeIds[providerTypeId].tag)
      } else {
        return PARENT_ID_MAPPING[parentId].tag
      }
    })
    .flat()

  return (
    <Stack
      direction="row"
      spacing="0"
      flexWrap="wrap"
      gap={(theme) => `${theme.spacing(theme.tokens.spacing.xs)} ${theme.spacing(theme.tokens.spacing.xxs)}`}
      sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.sm) })}
    >
      {tags.map((tag) => (
        <Chip size="small" key={intl.formatMessage(tag)} label={intl.formatMessage(tag)} />
      ))}
    </Stack>
  )
}
