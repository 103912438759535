import { ProviderFinder } from "pages/provider-finder"
import React from "react"
import { useSelector } from "react-redux"
import { ProviderFinderErrorReportingProvider } from "pages/provider-finder/context/ErrorReportingContext"
import { UserContextProvider } from "pages/provider-finder/context/UserContext"
import { ConfigContextProvider } from "pages/provider-finder/context/ConfigContext"
import { FormattedMessage } from "react-intl"
// should decouple the following imports in the long run
import Settings from "../../../../utils/CarrotConfig"
import { useAuthentication } from "../../../context/authentication/AuthenticationProvider"
import { getCompanyId, getCountryCode } from "reducers/companyInfo"
import { CompanyContextProvider } from "pages/provider-finder/context/CompanyContextProvider"
import { PageHeader, PageHeaderGlyph } from "../../../../features/global-ui/page-header"
import { PageLayout } from "../../../../features/global-ui/page-layout"
import { Stack, useTheme } from "@carrotfertility/carotene-core"

export const ProviderFinderAdapter = (): JSX.Element => {
  const theme = useTheme()
  const companyCountryCode = useSelector(getCountryCode)
  const companyId = useSelector(getCompanyId)

  const { isLoggedIn, handleSessionExpiration } = useAuthentication()

  return (
    <ConfigContextProvider config={Settings}>
      <ProviderFinderErrorReportingProvider>
        <UserContextProvider
          companyCountryCode={companyCountryCode}
          isLoggedIn={isLoggedIn}
          handleSessionExpiration={handleSessionExpiration}
        >
          <CompanyContextProvider companyId={companyId}>
            <PageLayout
              header={
                <Stack
                  marginBottom={theme.spacing(theme.tokens.spacing.xl)}
                  spacing={theme.spacing(theme.tokens.spacing.lg)}
                >
                  <PageHeader
                    startGlyph={
                      <PageHeaderGlyph src="/images/icn-providers.png" srcSet={"/images/icn-providers-2x.png"} />
                    }
                    pageTitle={<FormattedMessage defaultMessage="Provider finder" />}
                  />
                </Stack>
              }
            >
              <ProviderFinder />
            </PageLayout>
          </CompanyContextProvider>
        </UserContextProvider>
      </ProviderFinderErrorReportingProvider>
    </ConfigContextProvider>
  )
}
