export enum Steps {
  REQUEST_CARD_INFORMATION = "RequestCardInformation",
  MAILING_ADDRESS = "RequestCardMailingAddress",
  INTERNATIONAL_ADDRESS = "RequestCardInternationalAddress",
  CONFIRM_PHONE_NUMBER = "RequestCardConfirmPhoneNumber",
  VERIFY_PHONE_NUMBER = "RequestCardVerifyPhoneNumber",
  STRIPE_USER_AGREEMENT = "RequestCardStripeUserAgreement",
  REQUEST_CARD_SUCCESS = "RequestCardSuccess",
  CARD_REQUEST_PENDING = "CardRequestPending"
}
