import { useFlags } from "launchdarkly-react-client-sdk"
import { useCurrentUser } from "../../../components/context/user/UserContext"

type MedicalRecordConsentConfiguration = {
  companyExclusions: {
    companyId: number
    name: string
  }[]
  parentCompanyExclusions: {
    parentCompanyId: number
    name: string
  }[]
}

type GetIsExcludedFromMedicalRecordsConsentParams = {
  companyId: number
  parentCompanyId: number | null
}

export function useGetIsExcludedFromMedicalRecordsConsent(): ({
  companyId,
  parentCompanyId
}: GetIsExcludedFromMedicalRecordsConsentParams) => boolean {
  const {
    medicalRecordConsentConfiguration
  }: { medicalRecordConsentConfiguration: MedicalRecordConsentConfiguration } = useFlags()

  const excludedCompanyIds =
    medicalRecordConsentConfiguration?.companyExclusions?.map((exclusion) => exclusion.companyId) || []
  const excludedParentCompanyIds =
    medicalRecordConsentConfiguration?.parentCompanyExclusions?.map((exclusion) => exclusion.parentCompanyId) || []

  return ({ companyId, parentCompanyId }) => {
    const isCompanyExcluded = companyId && excludedCompanyIds.includes(companyId)
    const isParentCompanyExcluded = parentCompanyId && excludedParentCompanyIds.includes(parentCompanyId)
    return isCompanyExcluded || isParentCompanyExcluded
  }
}

export function useIsExcludedFromMedicalRecordsConsent(): boolean {
  const user = useCurrentUser()
  const companyId = user?.company?.companyId as number
  const parentCompanyId = user?.company?.parentCompanyId as number | null

  const getIsExcludedFromMedicalRecordsConsent = useGetIsExcludedFromMedicalRecordsConsent()
  return getIsExcludedFromMedicalRecordsConsent({ companyId, parentCompanyId })
}
