import React, { useContext, useEffect, useState } from "react"
import { StateMachineProvider, StateMachineStepView } from "../../context/stateMachine/StateMachineV2"
import { partnerRegistrationSection } from "./workflow"
import { useBodyClass } from "../../../utils/Hooks"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { LoadingInner } from "components/views/Loading"
import { reportError } from "../../../utils/ErrorReporting"
import { useGetRegisteringPartner } from "../hooks/usePartnerAccess"
import { HttpErrors } from "../../../utils/HttpErrors"
import { CarrotErrorCodes } from "../../../utils/CarrotErrors"
import { useHistory } from "react-router-dom"
import { useLocaleContext } from "../../context/user/LocaleContext"
import { useLocation } from "react-router"
import queryString from "query-string"
import { Locale, SupportedLocale } from "../../../types/Locale"
import { LaunchDarklyUser } from "../../../types/launchDarklyUser"

export const PartnerRegistrationFlowContext = React.createContext<PartnerRegistrationFlowContextProps>(null)

export const usePartnerRegistrationFlow = () => useContext(PartnerRegistrationFlowContext)

// @ts-expect-error TS7006
export default function PartnerRegistrationFlow(props): JSX.Element {
  useBodyClass("bg-warm-gray")
  const ldClient = useLDClient()
  const guid = props.match.params.guid
  const { registeringUser, getRegisteringPartnerError } = useGetRegisteringPartner(guid)
  const [password, setPassword] = useState(null)
  const [readPolicy, setReadPolicy] = useState(false)
  const [readTerms, setReadTerms] = useState(false)
  const [dataConsent, setDataConsent] = useState(false)
  const [emailOptIn, setEmailOptIn] = useState(false)
  const history = useHistory()
  const { setLocale } = useLocaleContext()
  const location = useLocation()

  const langFromParams = React.useMemo(() => {
    return queryString.parse(location.search).lang
  }, [location.search])

  const context = {
    guid,
    password,
    setPassword,
    readPolicy,
    setReadPolicy,
    readTerms,
    setReadTerms,
    dataConsent,
    setDataConsent,
    emailOptIn,
    setEmailOptIn
  }

  useEffect(() => {
    if (typeof langFromParams === "string" && SupportedLocale.isSupportedLocale(langFromParams)) {
      setLocale(Locale.getLocaleFromString(langFromParams))
    }
  }, [setLocale, langFromParams])

  useEffect(() => {
    async function loadLDFlagsForRegisteringPartner(): Promise<void> {
      if (ldClient && registeringUser) {
        await ldClient.waitUntilReady()

        const ldUser: LaunchDarklyUser = {
          key: registeringUser.email,
          email: registeringUser.email,
          custom: {
            companyId: registeringUser.companyId.toString(),
            isPartner: true,
            isEnrolled: false,
            isEngaged: false
          }
        }

        await ldClient.identify(ldUser)
      }
    }

    loadLDFlagsForRegisteringPartner().catch((error) => reportError(error))
  }, [ldClient, registeringUser])

  async function handleGetRegisteringPartnerError() {
    if (getRegisteringPartnerError.name === HttpErrors.BAD_REQUEST) {
      const errorResponse = await getRegisteringPartnerError.response.json()
      switch (errorResponse.status) {
        case "ALREADY_REGISTERED":
          await history.replace(`/?errorCode=${CarrotErrorCodes.PARTNER_ALREADY_REGISTERED}`)
          return
        case "NONE":
        case "CANNOT_REGISTER":
          await history.replace(`/?errorCode=${CarrotErrorCodes.PARTNER_INVITE_INACTIVE}`)
          return
      }
    }
    // Catching issue with CORS and 404s as well, which both are not actionable
    if ([HttpErrors.NOT_FOUND, "FetchError"].includes(getRegisteringPartnerError.name)) {
      await history.replace("/")
      return
    }
    reportError(getRegisteringPartnerError)
  }

  if (getRegisteringPartnerError) {
    handleGetRegisteringPartnerError()
    return null
  }

  if (!registeringUser) {
    return <LoadingInner />
  }
  return (
    <PartnerRegistrationFlowContext.Provider value={context}>
      <StateMachineProvider
        initialSection={partnerRegistrationSection.name}
        stateMachineDescription={[partnerRegistrationSection]}
        onComplete={null}
      >
        <StateMachineStepView />
      </StateMachineProvider>
    </PartnerRegistrationFlowContext.Provider>
  )
}

export type PartnerRegistrationFlowContextProps = {
  guid: string
  password: string
  setPassword: (password: string) => void
  readPolicy: boolean
  setReadPolicy: (arg: boolean) => void
  readTerms: boolean
  setReadTerms: (arg: boolean) => void
  dataConsent: boolean
  setDataConsent: (arg: boolean) => void
  emailOptIn: boolean
  setEmailOptIn: (arg: boolean) => void
}
