import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { Image, H2, Text, FlexContainer } from "@carrotfertility/carotene"
import React from "react"
import { FormattedMessage } from "react-intl"
import { DbgPaths, TtcPaths } from "../../../utils/Paths"
import { Spacer } from "../../views/atoms/Atoms"
import { Link, Stack, Container, useTheme, InternalIcon } from "@carrotfertility/carotene-core"
import ArrowSubmitButton from "../../../features/legacy-components/ArrowSubmitButton"

export const BeforeYouContinueWarning = (): JSX.Element => {
  const { send } = useStateMachine()
  const theme = useTheme()

  const handleContinueClick = (): void => {
    send("")
  }

  return (
    <>
      <Container>
        <Image
          src={`/images/round-error-sign.png`}
          altText=""
          width="196px"
          sx={{ display: "block", marginInlineStart: "auto", marginInlineEnd: "auto" }}
        />
        <Spacer height={1} />
        <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
          <H2>
            <FormattedMessage defaultMessage="Before you continue" />
          </H2>
          <Text>
            <FormattedMessage
              defaultMessage={
                "Please check three things to help us process your expense as " +
                "quickly as possible. If you have any questions, <link>contact us</link>."
              }
              values={{
                link: (linkContent) => (
                  <Link href={TtcPaths.TTC_CARE_NAV} target={"_blank"} color="inherit">
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Text>
          <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
            <Link
              endIcon={<InternalIcon fontSize="small" color="primary" />}
              href={DbgPaths.CARROT_ELIGIBLE}
              target="_blank"
            >
              <FormattedMessage defaultMessage="Make sure your expense is covered" />
            </Link>
            <Link
              endIcon={<InternalIcon fontSize="small" color="primary" />}
              href={DbgPaths.PROVIDER_ELIGIBILITY}
              target="_blank"
            >
              <FormattedMessage defaultMessage="Confirm that your provider is eligible" />
            </Link>
            <Link
              endIcon={<InternalIcon fontSize="small" color="primary" />}
              href={DbgPaths.REQUIRED_DOCS}
              target="_blank"
            >
              <FormattedMessage defaultMessage="Review the required documents you need to upload" />
            </Link>
          </Stack>
        </Stack>
      </Container>
      <FlexContainer paddingTopBottom="huge" justifyContent="flex-end">
        <ArrowSubmitButton onClick={handleContinueClick}>
          <FormattedMessage defaultMessage="Continue" />
        </ArrowSubmitButton>
      </FlexContainer>
    </>
  )
}
