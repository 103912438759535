/* eslint formatjs/no-literal-string-in-jsx: off -- translation planned for Q1 2024. See https://carrotfertility.atlassian.net/wiki/spaces/PROD/pages/2190215916/WS+Support+E2E+Translation */

import React from "react"
import { Text, Container, FlexContainer } from "@carrotfertility/carotene"

type CurrencyWarningProps = {
  companyCurrencyCode: string
  selectedCurrencyCode: string
}

export default function CurrencyWarning({
  companyCurrencyCode,
  selectedCurrencyCode
}: CurrencyWarningProps): JSX.Element {
  const showCurrencyError = selectedCurrencyCode === companyCurrencyCode

  return (
    <FlexContainer>
      {showCurrencyError ? null : (
        <Container sx={{ marginBlockEnd: "medium" }} background="lightGrey" padding="medium" borderRadius="8px">
          <Text weight="semibold">
            This currency does not match your company’s location. Your credit card statement may show this charge in
            your local currency.
          </Text>
        </Container>
      )}
    </FlexContainer>
  )
}
