import React, { useEffect, useMemo, useRef, useState } from "react"
import GoogleMapReact, { ChangeEventValue, MapOptions, Maps } from "google-map-react"
import { Provider } from "../../types/Providers"
import { useHistory } from "react-router-dom"
import {
  getProviderFinderSearchURL,
  ProviderFinderParam,
  RedirectToProviderFinderURL
} from "../../utils/providerFinderSearchUrl"
import { useUserContext } from "../../context/UserContext"
import { useGetLocationDetails } from "../../hooks/useGetLocationDetails"
import { useBroadAreaContext } from "../../context/BroadAreaContext"
import { useConfigContext } from "../../context/ConfigContext"
import { getHeap } from "../../utils/heap"
import { RecenterButton } from "./RecenterButton"
import { ProviderDetails } from "../results/ProviderDetails"
import { Marker } from "./Marker"
import { SearchThisLocationButton } from "./SearchThisLocationButton"
import {
  compareApproximateCenters,
  hideVirtualOnlyProvider,
  sortByCarrotPartnerAscending
} from "../../utils/mapViewUtil"
import { Benefit } from "pages/provider-finder/types/Benefit"
import { getPiiProps } from "services/tracking"
import { useIntl } from "react-intl"
import { Box } from "@carrotfertility/carotene-core"

type GoogleMap = google.maps.Map
type GoogleMapsLibrary = typeof google.maps

const MINIMUM_NUMBER_OF_PROVIDERS = 5

function createMapOptions(maps: Maps): MapOptions {
  return {
    zoomControl: true,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_TOP
    },
    fullscreenControl: false,
    streetViewControl: false
  }
}

export const MapView = ({ providers, benefit }: { providers: Provider[]; benefit: Benefit }): JSX.Element => {
  const history = useHistory()
  const { config } = useConfigContext()
  const { companyCountryCode } = useUserContext()
  const { setBroadAreaSearched } = useBroadAreaContext()
  const { locale } = useIntl()

  const params = new URLSearchParams(history.location.search)
  const searchLocationLatitude = Number(params.get(ProviderFinderParam.Latitude))
  const searchLocationLongitude = Number(params.get(ProviderFinderParam.Longitude))
  const searchLocationFromParams = params.get(ProviderFinderParam.Search)
  const carrotPartner = params.get(ProviderFinderParam.CarrotPartner) === "true"
  const isVirtual = params.get(ProviderFinderParam.IsVirtual) === "true"
  const providerType = params.get(ProviderFinderParam.ProviderType)
  const [searchLocation, setSearchLocation] = useState(null)
  const { data: locationDetails, isFetching, isError } = useGetLocationDetails(searchLocation)
  const isNewSearchDetailsLoaded = searchLocation && !isFetching && !isError

  const [selectedProvider, setSelectedProvider] = useState(null)
  const [showSearchThisLocation, setShowSearchThisLocation] = useState(false)
  const [searchLocationFromMap, setSearchLocationFromMap] = useState(null)
  const [initialPosition, setInitialPosition] = useState({ bounds: null, center: { lat: null, lng: null } })
  const mapInstance = useRef<GoogleMap>(null)
  const mapsInstance = useRef<GoogleMapsLibrary>(null)
  const debounceRef = useRef(null)
  const filteredAndSortedProviders = useMemo(
    () => providers.filter(hideVirtualOnlyProvider).sort(sortByCarrotPartnerAscending),
    [providers]
  )
  const keyword = ProviderFinderParam.Keyword && params.get(ProviderFinderParam.Keyword)

  const newSearchURL = isNewSearchDetailsLoaded
    ? getProviderFinderSearchURL({
        searchLocation,
        locationDetails,
        radiusInKm: "",
        companyCountryCode,
        carrotPartner,
        isVirtual,
        providerType,
        keyword
      })
    : null

  useEffect(() => {
    if (isNewSearchDetailsLoaded) {
      setBroadAreaSearched(locationDetails?.isBroadArea)
    }
  }, [isNewSearchDetailsLoaded, setBroadAreaSearched, locationDetails?.isBroadArea])

  const defaultMapProps = {
    center: {
      lat: searchLocationLatitude,
      lng: searchLocationLongitude
    },
    zoom: 14,
    hoverDistance: 300
  }

  // onMapChange is mandatorily called before onMapLoaded
  const onMapChange = ({ bounds, center }: ChangeEventValue): void => {
    // Check if the mapsInstance is not null to not display the search this location button on first render
    if (
      mapsInstance.current &&
      initialPosition.bounds &&
      initialPosition.center &&
      bounds?.sw?.lat &&
      bounds?.sw?.lng &&
      bounds?.ne?.lat &&
      bounds?.ne?.lng
    ) {
      const newBounds = new mapsInstance.current.LatLngBounds(bounds.sw, bounds.ne)
      const newBoundsEqualsInitialBounds = initialPosition.bounds.equals(newBounds)
      const newCenterEqualsInitialCenter = compareApproximateCenters(initialPosition.center, center)

      if (!newBoundsEqualsInitialBounds && !newCenterEqualsInitialCenter) {
        const geocodingService = new mapsInstance.current.Geocoder()
        const request = { location: center }
        let humanReadableAddressFromCenter

        geocodingService.geocode(request, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            // The reverse geocoding results are in descending order of level of details. i.e. street address at [0], country at last
            // Take the 60th percentile as the approximate search location, usually a neighborhood or a city
            const result = results[Math.floor(((results.length - 1) * 60) / 100)]
            const resultContainsPlusCode = result.types.includes("plus_code")
            const resultContainsCompoundPlusCode = result.plus_code?.compound_code !== undefined

            if (!resultContainsPlusCode || resultContainsCompoundPlusCode) {
              humanReadableAddressFromCenter = result.formatted_address
              setSearchLocationFromMap(humanReadableAddressFromCenter)
              setShowSearchThisLocation(true)
            } else {
              setSearchLocationFromMap(null)
              setShowSearchThisLocation(false)
            }
          }
        })
      }
    }
  }

  const debouncedOnMapChange = (event: ChangeEventValue): void => {
    setShowSearchThisLocation(false)
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }
    debounceRef.current = setTimeout(() => {
      onMapChange(event)
    }, 1000)
  }

  const onMapLoaded = (map: GoogleMap, maps: GoogleMapsLibrary): void => {
    getHeap().track("Provider Finder Map View Loaded")
    mapInstance.current = map
    mapsInstance.current = maps
    let currentBounds = mapInstance.current.getBounds()

    const furthestProviderIndex =
      providers.length < MINIMUM_NUMBER_OF_PROVIDERS ? providers.length - 1 : MINIMUM_NUMBER_OF_PROVIDERS - 1
    const showingEnoughProviders = currentBounds.contains(providers[furthestProviderIndex]?.location)
    if (!showingEnoughProviders) {
      // extend the bounds to include at least 5 providers and the search location
      const meaningfulBounds = new mapsInstance.current.LatLngBounds()
      meaningfulBounds.extend(new mapsInstance.current.LatLng(searchLocationLatitude, searchLocationLongitude))

      providers.slice(0, furthestProviderIndex + 1).forEach((provider) => {
        meaningfulBounds.extend(provider?.location)
      })
      mapInstance.current.fitBounds(meaningfulBounds, 10)
    }
    currentBounds = mapInstance.current.getBounds()
    const currentCenter = mapInstance.current.getCenter()
    setInitialPosition({
      bounds: currentBounds,
      center: { lat: currentCenter.lat(), lng: currentCenter.lng() }
    })
  }

  const onClickResetCenter = (): void => {
    mapInstance.current.fitBounds(initialPosition.bounds, 0)
    setShowSearchThisLocation(false)
  }

  const onClickSearchThisArea = (): void => {
    setSearchLocation(searchLocationFromMap)
  }

  const trackProviderSelected = (providerUuid: number): void => {
    getHeap().track("Selected Provider Finder Map Provider", {
      "Provider UUID": providerUuid
    })
  }

  const onClickMarker = (key: string): void => {
    const clickedProvider = filteredAndSortedProviders.find((provider) => provider.providerUuid === Number(key))
    const indexOfClickedProvider = filteredAndSortedProviders.indexOf(clickedProvider)
    filteredAndSortedProviders.splice(indexOfClickedProvider, 1)
    filteredAndSortedProviders.push(clickedProvider)
    mapInstance.current.panTo(clickedProvider?.location)

    setSelectedProvider(clickedProvider)

    trackProviderSelected(clickedProvider?.providerUuid)
  }

  return (
    <>
      <RedirectToProviderFinderURL url={newSearchURL} />
      <Box sx={{ position: "relative" }}>
        <Box
          {...getPiiProps()}
          height={["calc(100vh - 200px)", "calc(100vh - 232px)", "calc(100vh - 234px)"]} // Important! Always set the container height explicitly
          width="100%"
          borderRadius="8px"
          sx={{ position: "relative" }}
        >
          {/*We have to include the Places library when instantiating the map*/}
          {/*so that Autocomplete will still work if the map loads first*/}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: config.GOOGLE_PLACES_API,
              libraries: ["places"],
              language: locale // ❗ The langauge will not update if the user changes their locale after the Google Places API loads. See https://carrotfertility.atlassian.net/l/cp/90h5F13n#Doesn%E2%80%99t-update-when-locale-is-changed
            }}
            defaultCenter={defaultMapProps.center}
            defaultZoom={defaultMapProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }: { map: GoogleMap; maps: GoogleMapsLibrary }) => onMapLoaded(map, maps)}
            options={createMapOptions}
            onChildClick={onClickMarker}
            onClick={() => setSelectedProvider(null)}
            onChange={debouncedOnMapChange}
          >
            {filteredAndSortedProviders.map((provider) => {
              const { lat, lng } = provider?.location || {}

              return (
                <Marker
                  lat={lat}
                  lng={lng}
                  isCarrotPartner={provider.carrotPartner ?? false}
                  key={provider.providerUuid}
                  providerUuid={provider.providerUuid}
                  providerName={provider.providerName}
                  isSelected={provider.providerUuid === selectedProvider?.providerUuid}
                />
              )
            })}
          </GoogleMapReact>
        </Box>
        {showSearchThisLocation ? <SearchThisLocationButton onClick={onClickSearchThisArea} /> : null}
        {selectedProvider ? (
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "24px 16px",
              margin: "16px",
              position: "absolute",
              insetBlockEnd: 0,
              insetInlineStart: 0,
              insetInlineEnd: 0
            }}
          >
            <ProviderDetails provider={selectedProvider} searchLocation={searchLocationFromParams} benefit={benefit} />
          </Box>
        ) : (
          <RecenterButton onClick={onClickResetCenter} />
        )}
      </Box>
    </>
  )
}
