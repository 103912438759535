import { useLocation } from "react-router-dom"
import { ExpenseState } from "../../../../utils/ExpenseState"
import { useToggle } from "../../../../utils/Hooks"
import { Container, H2, Text } from "@carrotfertility/carotene"
import { SinglePathSVG } from "../../SVG"
import { GetReimbursed } from "../../../get-reimbursed"
import React from "react"
import { useIntl } from "react-intl"
import Settings from "../../../../utils/CarrotConfig"
import EmployeeOnlyModalContainer from "../../../employee-only-containers/EmployeeOnlyModalContainer"
import { Modal } from "../../modal/Modal"
import { Spacer } from "../../atoms/Atoms"
import { BodySmall } from "../../atoms/Bodies"
import { StylelessButton } from "../../molecules/Molecules"
import { Box, IconButton, OpenFileIcon, UploadIcon, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

const FileDrawingLink = ({ guid }) => {
  const intl = useIntl()
  return (
    <IconButton
      aria-label={intl.formatMessage({ defaultMessage: "View document" })}
      onClick={() => {
        window.open(`${Settings.CARROT_BACKEND_URL}/api/v2/statements/files/${guid}`, "_blank", "noreferrer")
      }}
      sx={(theme) => ({
        marginBlockStart: theme.spacing(theme.tokens.spacing.xs)
      })}
    >
      <OpenFileIcon />
    </IconButton>
  )
}
const FilePendingScan = () => {
  const [showPendingScanModal, togglePendingScanModal] = useToggle()
  const intl = useIntl()
  const PendingScanModal = () => (
    <EmployeeOnlyModalContainer onClose={togglePendingScanModal}>
      <Modal
        titleText={intl.formatMessage({ defaultMessage: "What does adjusted mean?" })}
        onExit={togglePendingScanModal}
        underlayClickExits={true}
      >
        <Container paddingLeftRight="huge" alignItems="center">
          <H2>{intl.formatMessage({ defaultMessage: "This file is currently unavailable" })}</H2>
          <Spacer height={1.5} />
          <BodySmall>
            {intl.formatMessage({ defaultMessage: "We're scanning your file for corruption. Please try again later." })}
          </BodySmall>
          <Spacer height={3} />
        </Container>
      </Modal>
    </EmployeeOnlyModalContainer>
  )
  return (
    <>
      <StylelessButton
        className="mr1 carrot-orange hover-dark-carrot-orange animate-all"
        onClick={togglePendingScanModal}
      >
        <SinglePathSVG icon="statementFile" />
      </StylelessButton>
      {showPendingScanModal && <PendingScanModal onExit={togglePendingScanModal} />}
    </>
  )
}
export const Attachments = ({ reimbursement }) => {
  const intl = useIntl()
  const queryParams = new URLSearchParams(useLocation().search)
  const enableUpload =
    reimbursement.expenseState === ExpenseState.PENDING_MEMBER_INFO ||
    reimbursement.expenseState === ExpenseState.DENIED_RECONCILIATION_REQUESTED

  const expenseId = queryParams.get("upload-expense-id")
  const isModalInitiallyOpen = expenseId === reimbursement.expenseId
  const [isReimbursementsModalOpen, toggleIsReimbursementsModalOpen] = useToggle(isModalInitiallyOpen)
  const theme = useTheme()
  const showInFullscreen = useMediaQuery(theme.breakpoints.up("sm"))
  if (enableUpload) {
    return (
      <>
        <Text
          className="carrot-orange hover-dark-carrot-orange animate-all"
          onClick={() => toggleIsReimbursementsModalOpen()}
          sx={{ cursor: "pointer" }}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "Upload documents" })}>
            <UploadIcon />
          </IconButton>
        </Text>
        {isReimbursementsModalOpen && (
          <GetReimbursed
            expenseId={reimbursement.expenseId}
            transDate={reimbursement.submittedDatetime}
            transAmount={reimbursement.amount}
            isOpen={isReimbursementsModalOpen}
            onExit={toggleIsReimbursementsModalOpen}
          />
        )}
      </>
    )
  } else {
    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row-reverse",
          flexWrap: "wrap",
          justifyContent: "start",
          paddingBlockStart: showInFullscreen ? "" : theme.spacing(theme.tokens.spacing.xs)
        })}
      >
        {reimbursement.fileGuids.map((guid) => (
          <FileDrawingLink key={guid} guid={guid} />
        ))}
        {[...Array(reimbursement.filesPendingScanCount)].map((x, i) => (
          <FilePendingScan key={`pendingFile-${i}`} />
        ))}
      </Box>
    )
  }
}
