enum CardStates {
  SHIPPED = "SHIPPED",
  ACTIVE = "ACTIVE",
  LOCKED = "LOCKED",
  PENDING = "PENDING",
  ELIGIBLE = "ELIGIBLE",
  ELIGIBLE_WITH_DEDUCTIBLE_NOT_MET = "ELIGIBLE_WITH_DEDUCTIBLE_NOT_MET",
  INELIGIBLE = "INELIGIBLE",
  NEEDS_ENROLLMENT = "NEEDS_ENROLLMENT",
  DEDUCTIBLE_NOT_MET = "DEDUCTIBLE_NOT_MET",
  RESTRICTION_PERIOD = "RESTRICTION_PERIOD",
  MANUAL_LOCK = "MANUAL_LOCK"
}

export type CardState = keyof typeof CardStates

export default CardStates
