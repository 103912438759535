// Most of this code is taken from https://github.com/sumup-oss/intl-js
// We used to use this library directly, but it has some strange behavior that we do not believe to be correct.
// Specifically, it is unable to handle the 'sr-Latn-RS' locale at all.
// It also is not compatible with the <NumericFormat> component from react-number-format in cases where
// a currency does not support decimal places (sub-units).

// This code takes the parts that do work and modifies the parts that don't to create a solution that works for us.

const TEST_VALUE = 1001001001.11111

export type ExtendedNumberFormat = Intl.ResolvedNumberFormatOptions & {
  groupDelimiter: string
  decimalDelimiter: string
  currencySymbol: string
  currencyPosition: "prefix" | "suffix"
}

function getPart(parts: Intl.NumberFormatPart[], name: Intl.NumberFormatPart["type"]): string | undefined {
  return parts.find((part) => part.type === name)?.value
}

function resolvePart(
  parts: Intl.NumberFormatPart[],
  name: Intl.NumberFormatPart["type"],
  locale?: string | undefined
): string {
  const currencyPart = getPart(parts, name)
  if (currencyPart) {
    return currencyPart
  }

  if (!locale) {
    return ""
  }

  // If the currency format does not have the desired part then look to the decimal format.
  const decimalFormat = new Intl.NumberFormat(locale, { style: "decimal" })
  const decimalParts = decimalFormat.formatToParts(TEST_VALUE)
  return getPart(decimalParts, name) ?? ""
}

export function resolveCurrencyFormat(locale: string, currency: string): ExtendedNumberFormat | null {
  if (!locale || !currency) {
    return null
  }

  const currencyFormat = new Intl.NumberFormat(locale, {
    style: "currency",
    currency
  })
  const resolvedOptions = currencyFormat.resolvedOptions()
  const parts = currencyFormat.formatToParts(TEST_VALUE)

  const groupDelimiter = resolvePart(parts, "group", locale)
  const decimalDelimiter = resolvePart(parts, "decimal", locale)
  const currencySymbol = resolvePart(parts, "currency")
  const currencyIndex = parts.findIndex((part) => part.type === "currency")
  const currencyPosition = currencyIndex === 0 ? "prefix" : "suffix"

  return {
    ...resolvedOptions,
    groupDelimiter,
    decimalDelimiter,
    currencySymbol,
    currencyPosition
  }
}
