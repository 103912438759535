import React, { useEffect } from "react"
import ReimbursementsList from "components/views/reimbursements/reimbursementsList/ReimbursementsList"
import Wrapper from "components/views/Wrapper"
import { BenefitResourcesSideBar } from "./BenefitResources"
import { useSelector } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"
import { getUserInfoLoaded } from "reducers/userInfo"
import { useToggle } from "utils/Hooks"
import { UpdateBankInfoModal } from "../../containers/reimbursements/UpdateBankInfoModal"
import { getShouldFillOutBankInfo } from "derivedSelectors/index"
import { BenefitEnrollmentFlowContainer } from "components/cmd-enrollment"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import EnrollmentReminder from "./enrollment-reminders"
import { BenefitAvailabilityStatus } from "types/benefitEnrollmentStatus"
import { GetReimbursed } from "components/get-reimbursed"
import { useLocation } from "react-router"
import { getSubsidies } from "../../../reducers/subsidies"
import { useCurrentUser } from "components/context/user/UserContext"
import EmployeeOnlySpaceContainer from "components/employee-only-containers/EmployeeOnlySpaceContainer"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import { Typography, Button, Container, Stack, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"
import { ErrorPage } from "../app/ErrorPage"
import { createErrorBoundary } from "../../../utils/createErrorBoundary"

const PlanNotStarted = () => (
  <Wrapper medium>
    <div className="lh-copy overflow-hidden w-100 h-100 br3-ns pa5-ns pa3 black-80">
      <Typography
        color="rgba(0, 0, 0, 0.9)"
        fontFamily="Publico Headline"
        fontWeight="700"
        lineHeight="1.25"
        marginTop="4rem"
        fontSize="16px"
      >
        <FormattedMessage defaultMessage="Hang tight!" />
      </Typography>
      <Typography>
        <FormattedMessage defaultMessage="Once your Carrot benefit kicks in, you can start using it here." />
      </Typography>
    </div>
  </Wrapper>
)

const SubmitReimbursementsButton = withRouter(() => {
  const shouldShowBankInfoModal = useSelector(getShouldFillOutBankInfo)
  const [isUpdateBankInfoModalOpen, toggleIsUpdateBankInfoModalOpen] = useToggle(false)
  const [isReimbursementsDialogOpen, toggleIsReimbursementsDialogOpen] = useToggle(false)
  const location = useLocation()

  const { availabilityStatus } = useBenefitEnrollment()

  function onNavigateToUpload() {
    if (shouldShowBankInfoModal) {
      toggleIsUpdateBankInfoModalOpen()
    } else {
      toggleIsReimbursementsDialogOpen()
    }
  }
  const isGetReimbursementsDisabled = availabilityStatus !== BenefitAvailabilityStatus.DOES_NOT_NEED_ENROLLMENT

  useEffect(() => {
    if (location.hash === "#get-reimbursed" && !isReimbursementsDialogOpen) {
      if (isGetReimbursementsDisabled) {
        return <Redirect to="/transactions" />
      }
      location.hash = ""
      onNavigateToUpload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [location.hash, toggleIsReimbursementsDialogOpen])

  return (
    <>
      <Button
        size="medium"
        onClick={onNavigateToUpload}
        color="secondary"
        variant="outlined"
        disabled={isGetReimbursementsDisabled}
      >
        <FormattedMessage defaultMessage="Get reimbursed" />
      </Button>
      {isUpdateBankInfoModalOpen && <UpdateBankInfoModal onClose={toggleIsUpdateBankInfoModalOpen} />}
      {isReimbursementsDialogOpen && (
        <GetReimbursed isOpen={isReimbursementsDialogOpen} onExit={toggleIsReimbursementsDialogOpen} />
      )}
    </>
  )
})

const ReimbursementHeader = () => {
  const intl = useIntl()
  const { isPartnerAccessingAccount } = useUserRole()
  const button = isPartnerAccessingAccount ? null : <SubmitReimbursementsButton />
  const theme = useTheme()
  return (
    <Stack marginBottom={theme.spacing(theme.tokens.spacing.xl)} spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <PageHeader
        startGlyph={
          <PageHeaderGlyph
            src="/images/icn-financial-support-header.png"
            srcSet={"/images/icn-financial-support-header-2x.png"}
          />
        }
        pageTitle={intl.formatMessage({ defaultMessage: "Financial support" })}
        endSlot={button}
      />
    </Stack>
  )
}

const TransactionsErrorPage = () => (
  <PageLayout>
    <Container justifyContent="center" alignItems="center" height="calc(100% - 133px)">
      <ErrorPage />
    </Container>
  </PageLayout>
)

const TransactionsPageErrorBoundary = createErrorBoundary(TransactionsErrorPage)
const ReimbursementsScreen = () => {
  const { availabilityStatus } = useBenefitEnrollment()
  const { refreshUserInfo } = useCurrentUser()
  const [showBenefitEnrollmentModal, toggleBenefitEnrollmentModal] = useToggle(false)
  const [showEnrollmentReminder, toggleEnrollmentReminder] = useToggle(
    availabilityStatus !== BenefitAvailabilityStatus.DOES_NOT_NEED_ENROLLMENT
  )

  useEffect(() => {
    refreshUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <>
      <TransactionsPageErrorBoundary>
        <PageLayout variant="sidebar" sidebar={<BenefitResourcesSideBar />} header={<ReimbursementHeader />}>
          <EmployeeOnlySpaceContainer>
            <ReimbursementsList />
          </EmployeeOnlySpaceContainer>
        </PageLayout>
        {showEnrollmentReminder && (
          <EnrollmentReminder
            availabilityStatus={availabilityStatus}
            onExit={() => toggleEnrollmentReminder()}
          ></EnrollmentReminder>
        )}
        {showBenefitEnrollmentModal && <BenefitEnrollmentFlowContainer onExit={toggleBenefitEnrollmentModal} />}
      </TransactionsPageErrorBoundary>
    </>
  )
}

const Transactions = () => {
  const subsidies = useSelector(getSubsidies)
  const userInfoLoaded = useSelector(getUserInfoLoaded)
  const { availabilityStatus } = useBenefitEnrollment()

  if (availabilityStatus === BenefitAvailabilityStatus.NEEDS_BENEFIT_ENROLLMENT) {
    return <Redirect to={"/#needs-benefit-enrollment"} />
  }

  if (userInfoLoaded) {
    if (!subsidies?.some((s) => s.planStarted)) {
      return <PlanNotStarted />
    }
    return <ReimbursementsScreen />
  } else {
    return null
  }
}

export default Transactions
