import React from "react"
import { Button, DialogActions, LeftIcon } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"

type CaseRateRenewalModalButtonGroupProps = {
  buttonOne: React.ReactElement
  buttonTwo: React.ReactElement
  back?: () => void
  disableBack?: boolean
}

export function CaseRateRenewalModalButtonGroup({
  buttonOne,
  buttonTwo,
  back,
  disableBack
}: CaseRateRenewalModalButtonGroupProps): JSX.Element {
  return (
    <DialogActions>
      {back && (
        <Button
          sx={{ marginInlineEnd: "auto" }}
          disabled={disableBack}
          variant="text"
          color="secondary"
          onClick={back}
          startIcon={<LeftIcon />}
        >
          <FormattedMessage defaultMessage="Back" />
        </Button>
      )}

      {buttonOne}

      {buttonTwo}
    </DialogActions>
  )
}
