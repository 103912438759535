import React from "react"
import styled from "styled-components"
import colors from "css/variables/_colors.scss"
import range from "lodash.range"
import { FlexContainer } from "@carrotfertility/carotene"

const ProgressBarSpacer = styled.div`
  inline-size: 6px;
`
const ProgressLabel = styled.div`
  font-family: Gotham A, Gotham B, Noto Sans, Noto Sans Thai Looped, sans-serif;
  margin-block-end: 0.5rem;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${colors.darkBlue};
`

const FullProgressBar = styled.div`
  flex: auto;
  background: ${(props) => (props.filled ? "#4984A8" : props.inactiveStepBackgroundColor)};
  border-radius: 4px;
  block-size: 4px;
`

const Progress = ({ inactiveStepBackgroundColor = "#FFFFFF", label, step, numSteps = 4 }) => (
  <>
    <ProgressLabel>{label}</ProgressLabel>
    <FlexContainer data-testid={"step number: " + step} stack="medium">
      {range(numSteps).map((index) => (
        <React.Fragment key={index}>
          <FullProgressBar inactiveStepBackgroundColor={inactiveStepBackgroundColor} filled={step > index} />
          {index < numSteps && <ProgressBarSpacer />}
        </React.Fragment>
      ))}
    </FlexContainer>
  </>
)

export { Progress }
