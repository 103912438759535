import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { getCompanyCurrencyCode } from "../../../../reducers/companyInfo"
import { ReimbursementDescriptionGroup, ReimbursementDescriptionList } from "./ReimbursementDescriptionList"
import React, { useEffect, useState } from "react"
import { Spacer } from "../../atoms/Atoms"
import { Container, FlexContainer, H2, H4, H5, Icon, Text } from "@carrotfertility/carotene"
import { BodySmall } from "../../atoms/Bodies"
import { getIsCarrotCardEligible } from "../../../../reducers/userInfo"
import { getReimbursementsList } from "../../../../reducers/reimbursements"
import { AltTextMultiPathSVG, NoAltMultiPathSVG } from "../../SVG"
import { getPiiProps } from "../../../../services/tracking"
import moment from "moment/moment"
import { ReimbursementAmount } from "./ReimbursementAmounts"
import { ExpenseState } from "../../../../utils/ExpenseState"
import { TextLink } from "../../atoms/links/TextLink"
import { reportErrorMessage } from "../../../../utils/ErrorReporting"
import { ReimbursementsListDeductibleString } from "./reimbursement-status-lists/ReimbursementsListDeductibleString"
import { ReimbursementsListPaidExpenseState } from "./reimbursement-status-lists/ReimbursementsListPaidExpenseState"
import { Attachments } from "../upload/Attachments"
import { useToggle } from "../../../../utils/Hooks"
import EmployeeOnlyModalContainer from "../../../employee-only-containers/EmployeeOnlyModalContainer"
import { Modal } from "../../modal/Modal"
import { UploadAdditionalDocumentsFlow } from "../../../upload-additional-documents"
import { useCurrentUser } from "../../../context/user/UserContext"
import { Alert, Box, Link, Stack, Typography, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"
import { getShouldFillOutBankInfo } from "derivedSelectors/index"
import { UpdateBankInfoModal } from "../../../containers/reimbursements/UpdateBankInfoModal"
import { TtcPaths } from "../../../../utils/Paths"

// Custom Elements (Header, Table)
const Header = ({ children, rightAlign = false }) => (
  <th>
    <Typography
      variant="body2"
      color="secondary"
      paddingBottom={(theme) => theme.tokens.spacing.xs}
      align={rightAlign ? "right" : "left"}
    >
      {children}
    </Typography>
  </th>
)
export const TableHeader = () => {
  const theme = useTheme()
  const showInFullscreen = useMediaQuery(theme.breakpoints.up("sm"))
  if (showInFullscreen) {
    return (
      <>
        <Header>
          <FormattedMessage defaultMessage="Type" description="the category" />
        </Header>
        <Header>
          <FormattedMessage defaultMessage="Submitted on" description="the date the expense was presented" />
        </Header>
        <Header rightAlign="true">
          <FormattedMessage defaultMessage="Uploads" description="files that have been submitted" />
        </Header>
      </>
    )
  } else {
    return <></>
  }
}

export const Legend = () => {
  const isCarrotCardEligible = useSelector(getIsCarrotCardEligible)
  const hasHadCarrotCardExp = useSelector(getReimbursementsList)
  const [hasPrevCarrotCardExpenses, setHasPrevCarrotCardExpenses] = useState(false)

  useEffect(() => {
    hasHadCarrotCardExp.forEach((x) => {
      if (x.isCardExpense) {
        if (!hasPrevCarrotCardExpenses) setHasPrevCarrotCardExpenses(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <div className="fr tr">
      {(isCarrotCardEligible || hasPrevCarrotCardExpenses) && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
            <FormattedMessage defaultMessage="Carrot Card®" />
          </Typography>
          <NoAltMultiPathSVG
            iconName="icn-carrot-card-key"
            className="dib ml2"
            ariaHidden="true"
            alt=""
            color="#2D2D2D"
          />
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="Out of pocket" description="money paid upfront" />
        </Typography>
        <NoAltMultiPathSVG iconName="icn-oop-key" className="dib ml2" ariaHidden="true" alt="" color="#2D2D2D" />
      </Box>
    </div>
  )
}

const ExpenseTypeIcon = ({ isCardExpense }) => {
  const intl = useIntl()
  return (
    <AltTextMultiPathSVG
      iconName={isCardExpense ? "badge-transaction-card" : "badge-transaction-oop"}
      altText={
        isCardExpense
          ? intl.formatMessage({ defaultMessage: "Carrot Card transaction" })
          : intl.formatMessage({ defaultMessage: "Out of pocket transaction" })
      }
    />
  )
}

const Details = ({ reimbursement }) => {
  const dateStringToDate = moment(reimbursement.submittedDatetime).toDate()
  return (
    <div>
      <div className="flex flex-row">
        <div className="flex-column mr3">
          <H5>
            <FormattedDate value={dateStringToDate} year="numeric" month="short" day="2-digit" />
          </H5>
        </div>
        <ReimbursementAmount reimbursement={reimbursement} />
      </div>
      <AdjustmentDetails reimbursement={reimbursement} />
      <Typography fontSize={14} margin="5px 0px">
        {reimbursement.merchantName}
      </Typography>
      <Text size="small" variant="accent">
        <RenderExpenseState reimbursement={reimbursement} />
      </Text>
    </div>
  )
}

const Row = ({ reimbursement }) => {
  const theme = useTheme()
  const showInFullscreen = useMediaQuery(theme.breakpoints.up("sm"))
  if (showInFullscreen) {
    return (
      <tr {...getPiiProps()} className="bt bw1 b--black-05">
        <td className="pv3 v-mid" style={{ width: "3em" }}>
          <ExpenseTypeIcon isCardExpense={reimbursement.isCardExpense} />
        </td>
        <td className="pv3 v-mid">
          <Details reimbursement={reimbursement} />
        </td>
        <td className="pv3">
          <div className="fr">
            {reimbursement.isBenefitTransfer ? (
              <BodySmall color="black-60">
                <FormattedMessage defaultMessage="Benefit Transfer" />
              </BodySmall>
            ) : (
              <Attachments reimbursement={reimbursement} />
            )}
          </div>
        </td>
      </tr>
    )
  } else {
    return (
      <Box
        className="bt bw1 b--black-05"
        display="flex"
        flexDirection="row"
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        <ExpenseTypeIcon isCardExpense={reimbursement.isCardExpense} />
        <Box marginLeft={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
          <Details reimbursement={reimbursement} />
          {reimbursement.isBenefitTransfer ? (
            <BodySmall color="black-60">
              <FormattedMessage defaultMessage="Benefit Transfer" />
            </BodySmall>
          ) : (
            <Attachments reimbursement={reimbursement} />
          )}
        </Box>
      </Box>
    )
  }
}

const AdjustmentDetails = ({ reimbursement }) => {
  const [isAdjustmentModalOpen, toggleIsAdjustmentsModalOpen] = useToggle(false)
  const intl = useIntl()
  const AdjustmentInfoModal = () => (
    <EmployeeOnlyModalContainer onClose={toggleIsAdjustmentsModalOpen}>
      <Modal
        titleText={intl.formatMessage({
          defaultMessage: "What does adjusted mean?"
        })}
        onExit={toggleIsAdjustmentsModalOpen}
        underlayClickExits={true}
      >
        <Container paddingLeftRight="huge" alignItems="center">
          <H2>
            <FormattedMessage defaultMessage="What does adjusted mean?" />
          </H2>
          <Spacer height={1.5} />
          <BodySmall>
            <FormattedMessage defaultMessage="We needed to make a change to your expense after we processed it. Reach out to your Care Team with any questions." />
          </BodySmall>
          <Spacer height={3} />
        </Container>
      </Modal>
    </EmployeeOnlyModalContainer>
  )
  const dateStringToDate = reimbursement.adjustmentDatetime ? moment(reimbursement.adjustmentDatetime).toDate() : null
  const formattedDate = dateStringToDate
    ? intl.formatDate(dateStringToDate, {
        month: "short",
        day: "2-digit"
      })
    : null
  return reimbursement.adjustmentDatetime ? (
    <>
      <FlexContainer alignItems="center">
        <BodySmall color="black-60">
          <span id="adjustment-date">
            <FormattedMessage defaultMessage="Adjusted on {formattedDate}" values={{ formattedDate }} />
          </span>
        </BodySmall>
        <button
          id="btn-adjustments-more-info"
          style={{ margin: "0 4px", border: "none", display: "flex", borderRadius: "100%", padding: "4px" }}
          className="pointer bg-white hover-bg-very-light-carrot-orange"
          aria-label={intl.formatMessage({
            defaultMessage: "Learn more about adjusted expense"
          })}
          onClick={() => toggleIsAdjustmentsModalOpen()}
        >
          <Icon name="helpCircle" color="carrotOrange" size="15px" />
        </button>
      </FlexContainer>
      {isAdjustmentModalOpen && <AdjustmentInfoModal />}
    </>
  ) : null
}

// Components Shared Between Larger Components
export const ReimbursementSummary = ({ subsidy, reimbursementsCount, showRemainingAnnual, showRemainingLifetime }) => {
  const intl = useIntl()
  const lifetimeAvailable = subsidy.lifetimeAmounts.available
  const yearlyAvailable = subsidy.yearlyAmounts.available
  const used = subsidy.lifetimeAmounts.claimed
  const companyCurrencyCode = useSelector(getCompanyCurrencyCode)

  return (
    <ReimbursementDescriptionList>
      {showRemainingAnnual && (
        <ReimbursementDescriptionGroup
          title={intl.formatMessage({
            defaultMessage: "Remaining annual",
            description: "the remaining amount of money allocated for the year"
          })}
          value={
            subsidy.hasUnlimitedBenefit
              ? intl.formatMessage({ defaultMessage: "Unlimited" })
              : intl.formatNumber(Math.max(yearlyAvailable / 100, 0), {
                  style: "currency",
                  currency: companyCurrencyCode,
                  currencyDisplay: "narrowSymbol",
                  maximumFractionDigits: 0
                })
          }
        />
      )}
      {showRemainingLifetime && (
        <ReimbursementDescriptionGroup
          title={intl.formatMessage({
            defaultMessage: "Remaining lifetime",
            description: "the remaining amount of money allocated for lifetime"
          })}
          value={
            subsidy.hasUnlimitedBenefit || subsidy.lifetimeAmounts.max === null
              ? intl.formatMessage({ defaultMessage: "Unlimited" })
              : intl.formatNumber(Math.max(lifetimeAvailable / 100, 0), {
                  style: "currency",
                  currency: companyCurrencyCode,
                  currencyDisplay: "narrowSymbol",
                  maximumFractionDigits: 0
                })
          }
        />
      )}
      <ReimbursementDescriptionGroup
        title={intl.formatMessage({
          defaultMessage: "Used",
          description: "money spent"
        })}
        value={intl.formatNumber(Math.max(used / 100, 0), {
          style: "currency",
          currency: companyCurrencyCode,
          currencyDisplay: "narrowSymbol",
          maximumFractionDigits: 0
        })}
      />
      <ReimbursementDescriptionGroup
        title={intl.formatMessage({ defaultMessage: "# of expenses", description: "number of expenses" })}
        value={intl.formatNumber(reimbursementsCount)}
      />
    </ReimbursementDescriptionList>
  )
}

export const ReimbursementsListSection = ({ title, items, emptyText = "", showAlert }) => {
  const hasItems = items && items.length > 0
  return (
    <div>
      <Spacer height={1} />
      <H4 variant="primary">{title}</H4>
      <Spacer height={1} />
      {showAlert && (
        <>
          <Alert severity="warning" sx={(theme) => ({ marginBlockEnd: theme.tokens.spacing.lg })}>
            <FormattedMessage
              defaultMessage="We need additional documents to process your expense. Please <link>check your messages</link> for details."
              values={{
                link: (linkContent) => (
                  <Link color="inherit" href={"/messages"}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Alert>
        </>
      )}
      <table className="w-100 collapse">
        <thead className={hasItems ? "" : "o-40"}>
          <tr>
            <TableHeader />
          </tr>
        </thead>
        <tbody>
          {hasItems ? (
            items.map((item) => <Row key={item.expenseId} reimbursement={item} />)
          ) : (
            <tr>
              <td colSpan="3" className="bt bw1 b--black-05 pv3">
                <BodySmall color="black-60 tc">{emptyText}</BodySmall>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const NeedsMemberUploadsPendingView = ({ reimbursement }) => {
  const intl = useIntl()
  const [isUploadAdditionalDocumentsDialogOpen, setIsUploadAdditionalDocumentsDialogOpen] = useState(false)
  const [isUpdateBankInfoModalOpen, toggleIsUpdateBankInfoModalOpen] = useToggle(false)
  const { refreshUserInfo } = useCurrentUser()
  const shouldShowBankInfoModal = useSelector(getShouldFillOutBankInfo)

  const toggleUploadAdditionalDocumentsModal = (event) => {
    event.preventDefault()
    if (shouldShowBankInfoModal && !reimbursement.isCardExpense) {
      toggleIsUpdateBankInfoModalOpen()
    } else {
      setIsUploadAdditionalDocumentsDialogOpen(true)
    }
  }

  const onClose = () => {
    refreshUserInfo()
    setIsUploadAdditionalDocumentsDialogOpen(false)
  }

  const requestedDate = moment(reimbursement.currentExpenseStateCreatedDate).toDate()
  const formattedRequestedDate = intl.formatDate(requestedDate, {
    month: "short",
    day: "2-digit"
  })
  return (
    <Stack color={(theme) => theme.palette.secondary.main}>
      <Typography variant="body2">
        <FormattedMessage
          defaultMessage="Documents requested {formattedRequestedDate}"
          values={{ formattedRequestedDate }}
        />
      </Typography>
      <Link
        color="inherit"
        component="button"
        variant="body2"
        alignSelf="flex-start"
        sx={{ marginBlockStart: "5px" }}
        onClick={toggleUploadAdditionalDocumentsModal}
      >
        <FormattedMessage defaultMessage="Add documents" />
      </Link>
      {isUpdateBankInfoModalOpen && <UpdateBankInfoModal onClose={toggleIsUpdateBankInfoModalOpen} />}

      {isUploadAdditionalDocumentsDialogOpen && (
        <UploadAdditionalDocumentsFlow
          expenseId={reimbursement.expenseId}
          onExit={onClose}
          isOpen={isUploadAdditionalDocumentsDialogOpen}
        />
      )}
    </Stack>
  )
}

export const RenderExpenseState = ({ reimbursement }) => {
  const intl = useIntl()
  const submitByDate = moment(reimbursement.submittedDatetime).toDate()

  // Set "Upload Statement By" date
  submitByDate.setDate(submitByDate.getDate() + 30)
  const dateStringToDate = moment(submitByDate).toDate()
  const formattedDate = intl.formatDate(dateStringToDate, {
    month: "short",
    day: "2-digit"
  })

  if (reimbursement.isCardExpense) {
    switch (reimbursement.expenseState) {
      case ExpenseState.PENDING_MEMBER_INFO:
        return (
          <FormattedMessage
            defaultMessage="Please upload an itemized statement by {formattedDate}"
            values={{ formattedDate }}
          />
        )
      case ExpenseState.NEEDS_MEMBER_UPLOADS:
        return <NeedsMemberUploadsPendingView reimbursement={reimbursement} />
      case ExpenseState.APPROVED:
        return <FormattedMessage defaultMessage="Approved" />
      case ExpenseState.DENIED_RECONCILED:
        return <FormattedMessage defaultMessage="Denied" />
      case ExpenseState.DENIED_RECONCILIATION_REQUESTED:
        return (
          <>
            <span>
              <FormattedMessage
                defaultMessage="Needs more info, please <link>contact us</link>"
                values={{
                  link: (linkContent) => (
                    <TextLink to={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </span>
          </>
        )
      case ExpenseState.ON_HOLD:
      case ExpenseState.PENDING_CARE_NAV:
        return <FormattedMessage defaultMessage="In progress" />
      default:
        reportErrorMessage(`Unexpected expense state provided for card expense: ${reimbursement.expenseState}`)
        return reimbursement.expenseState
    }
  } else {
    switch (reimbursement.expenseState) {
      case ExpenseState.ON_HOLD:
      case ExpenseState.RECEIVED:
        return <FormattedMessage defaultMessage="In progress" />
      case ExpenseState.NEEDS_MEMBER_UPLOADS:
        return <NeedsMemberUploadsPendingView reimbursement={reimbursement} />
      case ExpenseState.APPROVED:
        return (
          <>
            <FormattedMessage defaultMessage="Approved" />
            <ReimbursementsListDeductibleString reimbursement={reimbursement} />
          </>
        )
      case ExpenseState.PAID:
        return <ReimbursementsListPaidExpenseState reimbursement={reimbursement} />
      case ExpenseState.DENIED:
        return <FormattedMessage defaultMessage="Denied" />
      default:
        reportErrorMessage(`Unexpected expense state provided for OOP expense: ${reimbursement.expenseState}`)
        return reimbursement.expenseState
    }
  }
}
