import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Box, CloseIcon, Dialog, IconButton, DialogContent } from "@carrotfertility/carotene-core"

import {
  StateMachineProvider,
  StateMachineStepView as RequestCardView
} from "components/context/stateMachine/StateMachineV2"
import EmployeeOnlyModalContainer from "components/employee-only-containers/EmployeeOnlyModalContainer"

import { CountryCode } from "content/CountryCode"
import { requestCardSteps } from "pages/request-carrot-card-flow/workflows/step-library"
import { requestCardWorkflows, Workflows } from "pages/request-carrot-card-flow/workflows/workflows"
import { Steps } from "pages/request-carrot-card-flow/workflows/steps"
import { getIsUsa } from "reducers/userInfo"
import { useIntl } from "react-intl"

import { getIsAvenuesMember, getIsRomanHealthNYMandateMember, getIsNBAMember, getIsWNBAMember } from "derivedSelectors"

type RequestCardContextProps = {
  isUsaCompany?: boolean
  phoneNumber?: string
  countryCode?: CountryCode
  completeConfirmPhoneNumber?: (phoneNumber: string, countryCode: CountryCode, verificationCode?: string) => void
  verificationCode?: string
  showInformationScreen?: boolean
  onClose?: () => void
}

const RequestCardContext = React.createContext<RequestCardContextProps>({})

type RequestCardContainerState = {
  isUsaCompany?: boolean
  phoneNumber?: string
  countryCode?: CountryCode
  verificationCode?: string
  showInformationScreen?: boolean
  onClose?: () => void
}

const determineInitialScreen = (showInformationScreen: boolean, isUsa: boolean) => {
  if (showInformationScreen && isUsa) return Steps.REQUEST_CARD_INFORMATION

  if (!showInformationScreen && isUsa) return Steps.MAILING_ADDRESS

  return Steps.CONFIRM_PHONE_NUMBER
}

// @ts-expect-error TS7031
const RequestCardContainer = ({ onOpen, onExit }) => {
  const [state, setState] = useState<RequestCardContainerState>({})

  const completeConfirmPhoneNumber = (phoneNumber: string, countryCode: CountryCode, verificationCode: string) => {
    return setState({ ...state, phoneNumber, countryCode, verificationCode })
  }
  const intl = useIntl()

  const isUsa = useSelector(getIsUsa)
  const isAvenuesMember = useSelector(getIsAvenuesMember)
  const isNBAMember = useSelector(getIsNBAMember)
  const isRomanHealthNYMandateMember = useSelector(getIsRomanHealthNYMandateMember)
  const isWNBAMember = useSelector(getIsWNBAMember)

  const showInformationScreen = isAvenuesMember || isNBAMember || isRomanHealthNYMandateMember || isWNBAMember

  const initialStep = determineInitialScreen(showInformationScreen, isUsa)

  const context = {
    completeConfirmPhoneNumber: completeConfirmPhoneNumber,
    isUsaCompany: isUsa,
    phoneNumber: state.phoneNumber,
    countryCode: state.countryCode,
    verificationCode: state.verificationCode,
    showInformationScreen: showInformationScreen,
    onClose: onExit
  }

  const requestCardSection = {
    name: "RequestCard",
    initialStep,
    desiredWorkflow: Workflows.REQUEST_CARD,
    steps: requestCardSteps,
    workflows: requestCardWorkflows
  }

  return (
    <RequestCardContext.Provider value={context}>
      <StateMachineProvider
        initialSection="RequestCard"
        stateMachineDescription={[requestCardSection]}
        onComplete={onExit}
      >
        <EmployeeOnlyModalContainer onClose={onExit}>
          <Dialog open={onOpen} onClose={onExit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onExit}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogContent>
              <RequestCardView />
            </DialogContent>
          </Dialog>
        </EmployeeOnlyModalContainer>
      </StateMachineProvider>
    </RequestCardContext.Provider>
  )
}

export { RequestCardContext, RequestCardContainer }
