import React, { useState } from "react"
import { HttpErrors } from "utils/HttpErrors"
import { Form, FormButton, FormOnSubmitHandler, FormTextField } from "@carrotfertility/carotene-core-x"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@carrotfertility/carotene-core"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { carrotClient } from "utils/CarrotClient"

const client = carrotClient

const SERVER_ERRORS = {
  INVALID_PASSWORD: defineMessage({ defaultMessage: "Incorrect password" })
}

type ServerError = keyof typeof SERVER_ERRORS

type FormValues = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

export const ChangePasswordDialog = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  function onOpen() {
    setShowSuccess(false)
    setOpen(true)
  }

  function onClose() {
    setOpen(false)
  }

  function onSuccess() {
    setShowSuccess(true)
  }

  return (
    <>
      <Button sx={{ inlineSize: "fit-content" }} variant="outlined" color="secondary" onClick={onOpen}>
        <FormattedMessage defaultMessage="Change password" />
      </Button>
      <Dialog aria-labelledby="change-password-modal-title" open={open} onClose={onClose}>
        {showSuccess ? (
          <SuccessfulPasswordChange onClose={onClose} />
        ) : (
          <ChangePassword onClose={onClose} onSuccess={onSuccess} />
        )}
      </Dialog>
    </>
  )
}

function SuccessfulPasswordChange({ onClose }: { onClose: () => void }) {
  return (
    <>
      <DialogTitle id="change-password-modal-title">
        <FormattedMessage defaultMessage="Password updated" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage defaultMessage="Your password was successfully updated." />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}

function ChangePassword({ onClose, onSuccess }: { onClose: () => void; onSuccess: () => void }) {
  const intl = useIntl()

  const onSubmit: FormOnSubmitHandler<FormValues> = async (formData, formMethods) => {
    if (formData.newPassword !== formData.confirmNewPassword) {
      formMethods.setError("confirmNewPassword", {
        type: "custom",
        message: intl.formatMessage({ defaultMessage: "Password must match" })
      })
      return
    }

    try {
      await client.updatePassword(formData)
    } catch (error) {
      if (error.name === HttpErrors.BAD_REQUEST) {
        const textResponse = await error.response.text()
        const errors = JSON.parse(textResponse)
        Object.entries(errors).forEach(([key, value]: [keyof FormValues, Array<ServerError | string>]) => {
          let errorMessage = value[0]
          if (SERVER_ERRORS[errorMessage as ServerError]) {
            errorMessage = intl.formatMessage(SERVER_ERRORS[errorMessage as ServerError])
          }
          formMethods.setError(key, { type: key, message: errorMessage })
        })
        return
      }
    }

    onSuccess()
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Form onSubmit={onSubmit}>
        <DialogContent>
          <DialogContentText
            id="change-password-modal-title"
            variant="h2"
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
          >
            <FormattedMessage defaultMessage="Change your password" />
          </DialogContentText>
          <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
            <FormTextField
              type="password"
              name="currentPassword"
              label={intl.formatMessage({ defaultMessage: "Current password" })}
              registerOptions={{
                required: intl.formatMessage({ defaultMessage: "Required" })
              }}
            />
            <FormTextField
              type="password"
              name="newPassword"
              label={intl.formatMessage({ defaultMessage: "New password" })}
              registerOptions={{
                required: intl.formatMessage({ defaultMessage: "Required" }),
                minLength: {
                  message: intl.formatMessage({ defaultMessage: "Min 8 characters" }),
                  value: 8
                }
              }}
            />
            <FormTextField
              type="password"
              name="confirmNewPassword"
              label={intl.formatMessage({ defaultMessage: "Confirm password" })}
              registerOptions={{
                required: intl.formatMessage({ defaultMessage: "Required" }),
                minLength: {
                  message: intl.formatMessage({ defaultMessage: "Min 8 characters" }),
                  value: 8
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <FormButton variant="outlined" color="secondary" onClick={onClose}>
            <FormattedMessage defaultMessage="Cancel" />
          </FormButton>
          <FormButton type="submit">
            <FormattedMessage defaultMessage="Update now" />
          </FormButton>
        </DialogActions>
      </Form>
    </>
  )
}
