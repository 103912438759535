import React from "react"
import { FormAutocomplete, FormTextField } from "@carrotfertility/carotene-core-x"
import { CountryCodes } from "content/CountryCode"
import { AboutYou } from "types/carrotPlanTypes"
import { useIntl } from "react-intl"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import { useIntlSort } from "utils/hooks/useIntlSort"
import { MemberInternationalAddress } from "lib/carrot-api/types/Address"

export type InternationalAddressFields = Omit<AboutYou["address"], "address1" | "address2" | "internationalCountry"> & {
  internationalCountry: { label: string; value: string }
}

export function isValidInternationalAddress(address: Omit<AboutYou["address"], "address1" | "address2">) {
  return (
    Boolean(address?.internationalAddressLine) &&
    Boolean(address?.internationalCountry) &&
    Boolean(address?.city) &&
    Boolean(address?.state)
  )
}

export function formatInternationalAddressFieldsToPayload(fields: InternationalAddressFields) {
  return {
    address: {
      city: fields?.city,
      state: fields?.state,
      zip: fields?.zip,
      internationalCountry: fields?.internationalCountry.value,
      internationalAddressLine: fields?.internationalAddressLine
    }
  }
}

export interface InternationalAddressProps {
  defaultValues?: MemberInternationalAddress
}

export function InternationalAddress(props: InternationalAddressProps) {
  const intl = useIntl()
  const { localeComparator } = useIntlSort()
  const theme = useTheme()
  const requiredText = intl.formatMessage({ defaultMessage: "Required" })

  const countryOptions = CountryCodes.map((code) => ({
    label: intl.formatDisplayName(code, { type: "region" }),
    value: code
  })).sort((a, b) => localeComparator(a.label, b.label))

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <FormTextField
        minRows={4}
        multiline
        defaultValue={props?.defaultValues?.internationalAddressLine}
        registerOptions={{
          required: requiredText
        }}
        inputProps={{ "data-testid": "about-you-internationalAddressLine" }}
        label={intl.formatMessage({ defaultMessage: "Street Address" })}
        name="internationalAddressLine"
        helperText={intl.formatMessage({ defaultMessage: "Include apartment, suite, unit, building, floor, etc." })}
      />
      <FormTextField
        defaultValue={props?.defaultValues?.city}
        registerOptions={{
          required: requiredText
        }}
        inputProps={{ "data-testid": "about-you-city" }}
        label={intl.formatMessage({ defaultMessage: "City" })}
        name="city"
      />
      <FormTextField
        defaultValue={props?.defaultValues?.state}
        registerOptions={{
          required: requiredText
        }}
        inputProps={{ "data-testid": "about-you-state" }}
        label={intl.formatMessage({ defaultMessage: "State / Province / County / Region" })}
        name="state"
      />
      <FormAutocomplete
        fullWidth
        defaultValue={props?.defaultValues?.internationalCountry}
        controllerProps={{
          defaultValue: countryOptions.find(({ value }) => value === props?.defaultValues?.internationalCountry),
          rules: { required: requiredText }
        }}
        data-testid={"about-you-internationalCountry"}
        options={countryOptions}
        label={intl.formatMessage({ defaultMessage: "Country" })}
        name="internationalCountry"
      />
      <FormTextField
        defaultValue={props?.defaultValues?.zip}
        inputProps={{ "data-testid": "about-you-zip" }}
        label={intl.formatMessage({ defaultMessage: "Postal code" })}
        name="zip"
        helperText={intl.formatMessage({ defaultMessage: "Postal code is only required if your address has one." })}
      />
    </Stack>
  )
}
