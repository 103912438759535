import React, { ReactNode } from "react"
import { KeywordAutocomplete } from "./KeywordAutocomplete"
import { SearchIcon } from "@carrotfertility/carotene-core"

export const KeywordSearchInput = ({
  tooltipTitle,
  label,
  setProviderNameValue,
  setTypeaheadLocationSearch
}: {
  tooltipTitle: ReactNode
  label: string
  setProviderNameValue: (input: string) => void
  setTypeaheadLocationSearch: (location: string) => void
}): JSX.Element => {
  return (
    <KeywordAutocomplete
      tooltipTitle={tooltipTitle}
      label={label}
      popupIcon={null}
      startAdornment={
        <SearchIcon
          fontSize="medium"
          sx={(theme) => ({
            color: theme.palette.text.disabled,
            marginInlineStart: theme.spacing(theme.tokens.spacing.xxs),
            marginInlineEnd: theme.spacing(theme.tokens.spacing.xxs)
          })}
        />
      }
      setProviderNameValue={setProviderNameValue}
      setTypeaheadLocationSearch={setTypeaheadLocationSearch}
    />
  )
}
