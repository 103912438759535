import React from "react"
import { SidebarHelp } from "./SidebarHelp"
import { SidebarFilters } from "./SidebarFilters"
import { SwitchViewButton } from "./SwitchViewButton"
import { useMapViewContext } from "../../context/MapViewContext"
import { Benefit } from "pages/provider-finder/types/Benefit"
import { Box, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

export function ResultsSidebar({
  searchLocation,
  benefit
}: {
  searchLocation: string
  benefit: Benefit
  keyword: string
}): JSX.Element {
  const { showMapView } = useMapViewContext()
  const theme = useTheme()

  const element = (
    <Box
      display="flex"
      flexDirection="column"
      gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
      minWidth={232}
      maxWidth={320}
      sx={{
        blockSize: showMapView ? "calc(100vh - 224px)" : "auto",
        overflow: "unset",
        flexWrap: "nowrap",
        msOverflowStyle: "none",
        "::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      <SwitchViewButton />
      <Box
        display="flex"
        flexDirection="column"
        gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
        sx={{ overflowY: showMapView ? "scroll" : "unset" }}
      >
        <SidebarFilters searchLocation={searchLocation} benefit={benefit} />
        <SidebarHelp />
      </Box>
    </Box>
  )

  return useMediaQuery(theme.breakpoints.up("lg")) ? <span style={{ flexGrow: 1 }}>{element}</span> : null
}
