import React, { useCallback, useMemo } from "react"
import styled from "styled-components"
import { Spacer } from "../../atoms/Atoms"
import { Body, Container, SlidingDotsLoader } from "@carrotfertility/carotene"
import { SwitchH1ToH2 } from "../../atoms/Molecules"
import { Modal } from "../../modal/Modal"
import EmployeeOnlyModalContainer from "../../../employee-only-containers/EmployeeOnlyModalContainer"
import { DbgPaths } from "../../../../utils/Paths"
import { getMedicationCategorySections } from "./multiple-categories/MedicationsCategory"
import { getQmeCategorySections } from "./multiple-categories/QmeCategory"
import useBenefit from "../../../dynamic-content/hooks/useGetBenefit"
import { FinancialSupportErrorMessage } from "./FinancialSupportErrorMessage"
import { useSelector } from "react-redux"
import useCompanyComplianceConfigLineItemFilter from "../hooks/useGetCompanyComplianceConfigLineItemFilter"
import { Link, Typography, UnorderedList, UnorderedListItem } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { GetCategoryName } from "../ReimbursementCategoryMessages"
import { getCategoryCoverage } from "../../../../utils/CategoryCoverage"
import { getSubsidies } from "../../../../reducers/subsidies"
import { getPreservationSections } from "./multiple-categories/PreservationCategory"
import { getFertilityAndPreservationSections } from "./multiple-categories/FertilityAndPreservationCategory"
import { getFertilitySections } from "./multiple-categories/FertilityCategory"
import { getOtherExpensesSections } from "./multiple-categories/OtherExpensesCategory"
import { getMenopauseLowTestosteroneSections } from "./multiple-categories/MenopauseLowTestosteroneCategory"
import { getAdoptionAndGCSections } from "./multiple-categories/AdoptionAndGCCategory"
import { reimbursementCategoryNameMessageKeys } from "../reimbursementCategoryNameMessageKeys"

const BenefitDisplayPlanTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-inline-size: 600px;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  inline-size: 80%;
  align-self: center;
`

const ModalSectionHeaders = styled.span`
  font-weight: 500;
`

// @ts-expect-error TS7006
export const PayingForCareContentSub = (props) => {
  const { category, benefitInfo, lineItemFilter } = props
  const isOtherExpenses = useMemo(() => category === reimbursementCategoryNameMessageKeys.OTHER, [category])
  const preservation = benefitInfo.journeys.fertilityPreservation
  const fertilityCare = benefitInfo.journeys.fertilityCare
  const hasElectiveFertilityLineItemFilter = lineItemFilter === "ELECTIVE_FERTILITY"
  const showQmeInfertilityMessage =
    fertilityCare.eligibleExpensesDetail.isCoveredWithInfertilityDiagnosis ||
    preservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered
  const showOtherExpensesInfertilityMessage =
    fertilityCare.eligibleExpensesDetail.isElectiveCareCovered ||
    preservation.eligibleExpensesDetail.isElectivePreservationCovered

  return (
    <>
      {!hasElectiveFertilityLineItemFilter && (showQmeInfertilityMessage || showOtherExpensesInfertilityMessage) && (
        <div>
          <ModalSectionHeaders>
            <FormattedMessage defaultMessage="How do I know if I have an infertility diagnosis?" />
          </ModalSectionHeaders>
          <Spacer height={0.5} />
          <Body color="black-80">
            <p>
              <FormattedMessage
                defaultMessage={
                  "If you’re not sure, ask your fertility doctor. Based on local standards" +
                  " of practice, a doctor may diagnose infertility if pregnancy does not occur within a" +
                  " specific timeframe of unprotected intercourse or insemination."
                }
              />
            </p>
            <Spacer height={0.5} />
            <p>
              <FormattedMessage defaultMessage="An infertility diagnosis may also be issued due to:" />
            </p>
          </Body>
          <Spacer height={0.5} />
          <UnorderedList>
            <UnorderedListItem>
              <p className="lh-copy">
                <FormattedMessage defaultMessage="Ovulation issues or diminished ovarian reserve" />
              </p>
            </UnorderedListItem>
            <UnorderedListItem>
              <p className="lh-copy">
                <FormattedMessage defaultMessage="Problems with semen, sperm, or ejaculation" />
              </p>
            </UnorderedListItem>
            <UnorderedListItem>
              <p className="lh-copy">
                <FormattedMessage defaultMessage="Anatomical, endocrine, genetic, or functional differences in the reproductive system" />
              </p>
            </UnorderedListItem>
          </UnorderedList>
        </div>
      )}
      <Spacer height={1.0} />
      {!isOtherExpenses &&
        !hasElectiveFertilityLineItemFilter &&
        preservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered && (
          <div>
            <ModalSectionHeaders>
              <FormattedMessage defaultMessage="How do I know if my preservation care is medically necessary?" />
            </ModalSectionHeaders>
            <Spacer height={0.5} />
            <Body color="black-80">
              <FormattedMessage
                defaultMessage={
                  "If you’re not sure, ask your fertility doctor. Medical necessity" +
                  " for fertility preservation is determined by a doctor based on health issues or treatments that" +
                  " could significantly impact the reproductive system. For example, preservation may be considered " +
                  "medically necessary before undergoing certain types of chemotherapy or surgery."
                }
              />
            </Body>
          </div>
        )}
      {isOtherExpenses &&
        !hasElectiveFertilityLineItemFilter &&
        preservation.eligibleExpensesDetail.isElectivePreservationCovered && (
          <div>
            <ModalSectionHeaders>
              <FormattedMessage defaultMessage="How do I know if my care is medically necessary?" />
            </ModalSectionHeaders>
            <Spacer height={0.5} />
            <Body color="black-80">
              <FormattedMessage
                defaultMessage={
                  "If you’re not sure, ask your fertility doctor. Medical necessity" +
                  " for fertility preservation is determined by a doctor based on health issues or treatments that" +
                  " could significantly impact the reproductive system. For example, preservation may be considered " +
                  "medically necessary before undergoing certain types of chemotherapy or surgery."
                }
              />
            </Body>
          </div>
        )}
    </>
  )
}

// @ts-expect-error TS7006
const PayingForCareContentMain = (props): JSX.Element => {
  const { category } = props
  const { data: benefit, isLoading } = useBenefit()
  const { data } = useCompanyComplianceConfigLineItemFilter()
  const coverage = getCategoryCoverage(useSelector(getSubsidies))

  const sections = getCategorySections(category)

  if ((data == null || benefit == null) && !isLoading) {
    return <FinancialSupportErrorMessage />
  }

  return isLoading ? (
    <SlidingDotsLoader />
  ) : (
    <ModalContainer>
      <Container padding="none" stack="large">
        <SwitchH1ToH2>{GetCategoryName(category, coverage.qme)}</SwitchH1ToH2>
        <Spacer height={1.0} />
        <div>
          <Body color="black-80">{sections.header}</Body>
          <Spacer height={0.5} />
          <UnorderedList>{sections.listItems}</UnorderedList>
          {sections.footer && (
            <>
              <Spacer height={0.5} />
              <Body color="black-80">{sections.footer}</Body>
            </>
          )}
        </div>
        {sections.includeSub && (
          <>
            <Spacer height={1.0} />
            <PayingForCareContentSub category={category} benefitInfo={benefit} lineItemFilter={data} />
          </>
        )}
      </Container>
    </ModalContainer>
  )
}

export function PayingForCareCoverageModal({
  // @ts-expect-error TS7031
  element
}): JSX.Element {
  const [showModal, setShowModal] = React.useState(false)
  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [])
  const handleExit = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <BenefitDisplayPlanTextContainer onClick={(event) => event.stopPropagation()}>
      {element.length > 1 && <body className="mr3 ml3 black-80"> {element.reimbursementCategoryName} </body>}
      {useCategoryLink(element.reimbursementCategoryNameMessageKey, handleOpen)}
      {showModal && (
        <EmployeeOnlyModalContainer onClose={handleExit}>
          <Modal onExit={handleExit} titleText={element.reimbursementCategoryName}>
            <PayingForCareContentMain category={element.reimbursementCategoryNameMessageKey} />
            <Spacer height={3.0} />
          </Modal>
        </EmployeeOnlyModalContainer>
      )}
    </BenefitDisplayPlanTextContainer>
  )
}

function useCategoryLink(category: string, handleOpen: () => void): React.JSX.Element {
  const { data: benefit } = useBenefit()
  let href: string = null
  switch (category) {
    case reimbursementCategoryNameMessageKeys.ADOPTION: {
      href = DbgPaths.ADOPTION_COVERAGE
      break
    }
    case reimbursementCategoryNameMessageKeys.GC: {
      href = DbgPaths.GESTATIONAL_SURROGACY_COVERAGE
      break
    }
    case reimbursementCategoryNameMessageKeys.FERTILITY: {
      if (
        !benefit?.journeys.fertilityCare.eligibleExpensesDetail.isElectiveCareCovered &&
        !benefit?.journeys.fertilityCare.eligibleExpensesDetail.isCoveredWithInfertilityDiagnosis
      ) {
        href = DbgPaths.FERTILITY_COVERAGE
      }
      break
    }
    case reimbursementCategoryNameMessageKeys.GAC: {
      href = DbgPaths.GENDER_AFFIRMING_CARE
      break
    }
    case reimbursementCategoryNameMessageKeys.PREGNANCY: {
      href = DbgPaths.PREGNANCY_COVERAGE
      break
    }
    case reimbursementCategoryNameMessageKeys.PRESERVATION: {
      if (
        benefit?.journeys.fertilityPreservation.eligibleExpensesDetail.isElectivePreservationCovered ||
        benefit?.journeys.fertilityPreservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered ||
        benefit?.journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance
      ) {
        href = DbgPaths.PRESERVATION_COVERAGE
      }
      break
    }
  }

  return (
    <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
      <FormattedMessage
        defaultMessage="<link>Learn</link> what this includes"
        values={{
          link: (linkContent) =>
            href ? (
              <Link
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                }}
                href={href}
                color="inherit"
                sx={{
                  pointerEvents: "auto"
                }}
              >
                {linkContent}
              </Link>
            ) : (
              <Link
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  handleOpen()
                }}
                color="inherit"
                component="button"
                sx={{
                  pointerEvents: "auto"
                }}
              >
                {linkContent}
              </Link>
            )
        }}
      />
    </Typography>
  )
}

export interface CategorySections {
  header: React.JSX.Element
  listItems?: React.JSX.Element
  footer?: React.JSX.Element
  includeSub?: boolean
}

function getCategorySections(category: string): CategorySections {
  switch (category) {
    case reimbursementCategoryNameMessageKeys.ADOPTION_AND_GC: {
      return getAdoptionAndGCSections()
    }
    case reimbursementCategoryNameMessageKeys.MEDICATION: {
      return getMedicationCategorySections()
    }
    case reimbursementCategoryNameMessageKeys.QME: {
      return getQmeCategorySections()
    }
    case reimbursementCategoryNameMessageKeys.M_LT:
    case reimbursementCategoryNameMessageKeys.M_LT_GAC:
    case reimbursementCategoryNameMessageKeys.PREGNANCY_M_LT: {
      return getMenopauseLowTestosteroneSections(category)
    }
    case reimbursementCategoryNameMessageKeys.FERTILITY: {
      return getFertilitySections()
    }
    case reimbursementCategoryNameMessageKeys.FERTILITY_AND_PRESERVATION: {
      return getFertilityAndPreservationSections()
    }
    case reimbursementCategoryNameMessageKeys.PRESERVATION: {
      return getPreservationSections()
    }
  }
  return getOtherExpensesSections()
}
