import React from "react"
import PropTypes from "prop-types"
import { H4 } from "@carrotfertility/carotene"
import styled from "styled-components"
import { BodySmall } from "components/views/atoms/Bodies"
import { FormattedMessage } from "react-intl"

const HelperText = ({ children, color = "black" }) => <StyledHelperText className={color}>{children}</StyledHelperText>

const StyledHelperText = styled.span`
  font-family: Gotham A, Gotham B, Noto Sans, Noto Sans Thai Looped, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
`

const ButtonLabel = ({ children }) => (
  <span
    style={{
      fontFamily: "Gotham A, Gotham B, Noto Sans, Noto Sans Thai Looped, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "20px"
    }}
    className="black-80"
  >
    {children}
  </span>
)

const StatusBubble = ({ backgroundColor, status }) => (
  <div className={`pa2 br2 bg-${backgroundColor}`}>
    <div className={`f8 b tracked-half ttu mv0 white`}>{status}</div>
  </div>
)

const FlexRowCenter = ({ children }) => <div className="flex justify-center">{children}</div>

const Spacer = ({ height, width, expand }) => {
  const flex = expand ? "flex-auto " : ""
  const heightValue = height * 16 + "px"
  const widthValue = width * 16 + "px"

  return <div style={{ height: heightValue, width: widthValue, flexShrink: 0 }} className={flex} />
}

Spacer.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  expand: PropTypes.bool
}

const Label = ({ valid, htmlFor, children }) => {
  // This eventually should be replaced by the carotene label
  return (
    <label htmlFor={htmlFor}>
      <H4
        variant="primary"
        style={{
          color: valid ? "inherit" : "#d26556"
        }}
      >
        {children}
      </H4>
    </label>
  )
}

// eslint-disable-next-line formatjs/no-literal-string-in-jsx
const Asterisk = ({ color = "invalid-red" }) => <span className={color}>*</span>

const HR = ({ color, width = 1 }) => <div style={{ borderWidth: width }} className={`bb b--${color}`} />

HR.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string.isRequired
}

const TwoColumnList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 16px -8px 0 -8px;
  padding: 0;

  li {
    display: inline-block;
    font-size: 14px;
    inline-size: calc(50% - 32px);
    margin: 0 24px 12px 24px;
    &:nth-child(even) {
      inline-size: calc(50% - 48px);
      margin-inline-end: 0px;
    }
    &:nth-last-child(2) {
      margin-block-end: 0;
    }
  }
`
const LoadingText = () => (
  <BodySmall color={"black-80"} display="di" weight={"normal"} cursor={"wait"} opacity={0.5}>
    <FormattedMessage defaultMessage="Loading..." />
  </BodySmall>
)

export {
  ButtonLabel,
  HelperText,
  // Layout elements
  Spacer,
  HR,
  FlexRowCenter,
  //Icons
  StatusBubble,
  //Inputs
  Label,
  Asterisk,
  TwoColumnList,
  LoadingText
}
