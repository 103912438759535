import React from "react"
import InsuranceDetails from "components/insurance-details"
import { InsuranceDetailsProvider } from "components/insurance-details/context/useInsuranceDetails"
// import { CenteredLayout } from "components/views/atoms/Layouts"
import { ReimbursementStatusProvider } from "components/context/reimbursement/ReimbursementStatusContext"
import { Container } from "@carrotfertility/carotene"

import { DeductibleProvider } from "components/context/user/DeductibleContext"
import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"
import { Redirect } from "react-router-dom"
import EmployeeOnlySpaceContainer from "components/employee-only-containers/EmployeeOnlySpaceContainer"
import { useIntl } from "react-intl"
import { PageLayout } from "features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"

const InsuranceDetailsPage = (): JSX.Element => {
  const { shouldDisplayInsuranceDetails } = useBenefitEnrollment()

  const intl = useIntl()
  const InsuranceHeader = (): JSX.Element => (
    <PageHeader
      startGlyph={<PageHeaderGlyph src="/images/icn-insurance.png" srcSet="/images/icn-insurance-2x.png" />}
      pageTitle={intl.formatMessage({ defaultMessage: "Insurance details" })}
    />
  )

  if (!shouldDisplayInsuranceDetails) {
    return <Redirect to="/" />
  }

  return (
    <PageLayout header={<InsuranceHeader />}>
      <EmployeeOnlySpaceContainer>
        <DeductibleProvider>
          <InsuranceDetailsProvider>
            <ReimbursementStatusProvider>
              <Container sx={{ paddingBlockEnd: "64px" }}>
                <InsuranceDetails />
              </Container>
            </ReimbursementStatusProvider>
          </InsuranceDetailsProvider>
        </DeductibleProvider>
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

export default InsuranceDetailsPage
