import React from "react"
import { Box } from "@carrotfertility/carotene-core"
import { MainNavigation } from "./MainNavigation"
import { FormattedMessage } from "react-intl"

//some of this will go away when we switch the mobile breakpoint to lg
const drawerWidth = 256

const skipToMainContent = (
  <Box
    sx={(theme) => ({
      insetInlineStart: "-999px",
      position: "absolute",
      insetBlockStart: "auto",
      inlineSize: "1px",
      blockSize: "1px",
      overflow: "hidden",
      zIndex: "-999",
      "&:focus, &:active": {
        padding: theme.spacing(theme.tokens.spacing.sm),
        backgroundColor: theme.palette.background.default,
        outlineColor: theme.palette.focusRing,
        outlineWidth: theme.tokens.borderWidth.md,
        insetInlineStart: theme.spacing(theme.tokens.spacing.sm),
        insetBlockStart: theme.spacing(theme.tokens.spacing.sm),
        textDecoration: "underline",
        inlineSize: "unset",
        blockSize: "unset",
        zIndex: 99999
      }
    })}
    component="a"
    className="skip-main"
    href="#main"
  >
    <FormattedMessage defaultMessage="Skip to main content" />
  </Box>
)
export function NavLayout(props: any) {
  return (
    <Box
      sx={{
        inlineSize: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { lg: `${drawerWidth}px` }
      }}
    >
      {skipToMainContent}
      <Box component="nav" sx={{ inlineSize: { lg: drawerWidth }, flexShrink: { md: 0 } }}>
        <MainNavigation />
      </Box>
      <Box id="main" component="main" flexGrow={1}>
        {props.children}
      </Box>
    </Box>
  )
}
