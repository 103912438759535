import React, { FC } from "react"
import { Link, Typography, useTheme, Box, Container, Button, Alert } from "@carrotfertility/carotene-core"
interface ActivateTemplateProps {
  header: React.ReactNode
  imageComponent?: React.ReactNode
  body: React.ReactNode
  linkText?: React.ReactNode
  linkUrl?: string
  buttonLink?: string
  buttonText: string
  buttonOnClick?: React.MouseEventHandler<HTMLAnchorElement>
  buttonDisabled?: boolean
  errorMessage?: React.ReactNode
}
const ActivateTemplate: FC<ActivateTemplateProps> = ({
  header,
  imageComponent,
  body,
  linkText,
  linkUrl,
  buttonLink,
  buttonText,
  buttonOnClick,
  buttonDisabled = false,
  errorMessage
}: ActivateTemplateProps) => {
  const theme = useTheme()
  return (
    <>
      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" alignItems="center">
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {imageComponent}
          <Typography
            variant="h3"
            component="h1"
            color={theme.palette.text.primary}
            paddingTop={errorMessage ? theme.tokens.spacing.xl : 0}
          >
            {header}
          </Typography>
          <Typography
            variant="body1"
            color={theme.palette.text.secondary}
            paddingTop={theme.tokens.spacing.xxs}
            align="center"
          >
            {body}
          </Typography>
          {linkText && (
            <Link href={linkUrl} paddingTop={theme.tokens.spacing.md}>
              {linkText}
            </Link>
          )}
          <Button
            variant="contained"
            component={buttonLink ? "a" : "button"}
            href={buttonLink}
            onClick={buttonOnClick}
            sx={{ marginBlockStart: theme.spacing(theme.tokens.spacing.xl) }}
            disabled={buttonDisabled}
          >
            {buttonText}
          </Button>
        </Box>
      </Container>
    </>
  )
}

export default ActivateTemplate
