import { FormatDateOptions, FormattedMessage, useIntl } from "react-intl"
import { useFlags } from "launchdarkly-react-client-sdk"
import { Box, Tile, Typography } from "@carrotfertility/carotene-core"
import React, { ReactElement } from "react"

export default function ConditionalSiteMaintenanceAlert(): ReactElement {
  return (
    <LaunchDarklySiteMaintenanceAlertContainer>
      <SiteMaintenanceAlert />
    </LaunchDarklySiteMaintenanceAlertContainer>
  )
}
function SiteMaintenanceAlert(): ReactElement {
  const { siteMaintenance } = useFlags()
  const {
    isError,
    startDateFormatted: startDate,
    startTimeFormatted: startTime,
    endDateFormatted: endDate,
    endTimeFormatted: endTime
  } = useExtractAndFormatSiteMaintenanceDates(siteMaintenance)

  return (
    !isError && (
      <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        <Tile>
          <Typography>
            <FormattedMessage
              defaultMessage="The Carrot app will undergo scheduled maintenance on {startDate} from {startTime} to {endDate} {endTime}. During this time, you may experience temporary service disruptions. We apologize for any inconvenience and appreciate your patience."
              values={{
                startDate,
                startTime,
                endDate,
                endTime
              }}
            />
          </Typography>
        </Tile>
      </Box>
    )
  )
}

const LaunchDarklySiteMaintenanceAlertContainer = ({ children }: { children: ReactElement }): ReactElement => {
  const { siteMaintenance } = useFlags()

  if (!siteMaintenance) {
    return null
  }

  return children
}

function useExtractAndFormatSiteMaintenanceDates(siteMaintenance: string | undefined): FormattedSiteMaintenanceDates {
  let dateTimeStrings: string[] = ["", ""]
  let isError = false

  if (siteMaintenance && siteMaintenance.includes(",")) {
    dateTimeStrings = siteMaintenance.split(",").map((dateString: string) => dateString.trim())
  } else {
    isError = true
  }

  const startDateTimeRaw = dateTimeStrings[0]
  const endDateTimeRaw = dateTimeStrings[1]

  const formattedStartTimeResult = useTryFormatTime(startDateTimeRaw)
  const formattedStartDateResult = useTryFormatDate(startDateTimeRaw, {
    month: "2-digit",
    day: "2-digit"
  })
  const formattedEndTimeResult = useTryFormatTime(endDateTimeRaw, { timeZoneName: "short" })
  const formattedEndDateResult = useTryFormatDate(endDateTimeRaw, {
    month: "2-digit",
    day: "2-digit"
  })

  if (
    formattedStartTimeResult.isError ||
    formattedEndTimeResult.isError ||
    formattedStartDateResult.isError ||
    formattedEndDateResult.isError
  ) {
    isError = true
  }

  return {
    isError,
    startDateFormatted: formattedStartDateResult.formattedString,
    startTimeFormatted: formattedStartTimeResult.formattedString,
    endDateFormatted: formattedEndDateResult.formattedString,
    endTimeFormatted: formattedEndTimeResult.formattedString
  }
}

function useTryFormatDate(dateTimeRaw: string, options?: FormatDateOptions): FormattedDateTime {
  return useTryFormat(useIntl().formatDate, dateTimeRaw, options)
}

function useTryFormatTime(dateTimeRaw: string, options?: FormatDateOptions): FormattedDateTime {
  return useTryFormat(useIntl().formatTime, dateTimeRaw, options)
}

function useTryFormat(
  formatFunction: (value: string, options?: FormatDateOptions) => string,
  dateTimeRaw: string,
  options?: FormatDateOptions
): FormattedDateTime {
  const intl = useIntl()
  const formattedDateTime = formatFunction.call(intl, dateTimeRaw, options)
  return { isError: formattedDateTime === "Invalid Date", formattedString: formattedDateTime }
}

interface FormattedSiteMaintenanceDates extends ErrorProne {
  startDateFormatted?: string
  startTimeFormatted?: string
  endDateFormatted?: string
  endTimeFormatted?: string
}

interface FormattedDateTime extends ErrorProne {
  formattedString?: string
}

interface ErrorProne {
  isError: boolean
}
