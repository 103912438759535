import { Box, Divider, Grid, Stack, Theme, Typography } from "@carrotfertility/carotene-core"
import React from "react"
import { DigitalFrontDoorSectionLayout } from "./DigitalFrontDoorSectionLayout"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import Medical from "../resources/images/medical.svg"
import Messages from "../resources/images/messages.svg"
import DesktopandMobileApp from "../resources/images/desktop-mobile-app.svg"
import { CircleImage } from "./CircleImage"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"

const gridItemData = [
  {
    imgSrc: Medical,
    imgHexCode: "#C8E248",
    title: defineMessage({ defaultMessage: "Support from certified clinicians" }),
    description: defineMessage({
      defaultMessage: "Get priority access to certified clinicians for personalized support."
    })
  },
  {
    imgSrc: Messages,
    imgHexCode: "#95C0D8",
    title: defineMessage({ defaultMessage: "On-call pharmacists 24/7" }),
    description: defineMessage({ defaultMessage: "Speak to a pharmacist any time on a dedicated phone line." })
  },
  {
    imgSrc: DesktopandMobileApp,
    imgHexCode: "#D7A8EC",
    title: defineMessage({ defaultMessage: "Easy-to-use mobile app" }),
    description: defineMessage({
      defaultMessage: "Place, track, and manage your orders through Alto’s convenient mobile app."
    })
  }
]

export function ExceptionalCareSection({ backgroundColor }: { backgroundColor: string }) {
  const intl = useIntl()

  const { isMobileView, isUnderXLScreenView } = useDigitalFrontDoorBreakpointViews()

  return (
    <Box id="exceptional-care">
      <DigitalFrontDoorSectionLayout backgroundColor={backgroundColor}>
        <Stack
          paddingY={(theme: Theme) => (isMobileView ? theme.tokens.spacing.xxl : "7.5rem")}
          gap={(theme) => (isUnderXLScreenView ? theme.tokens.spacing.xl : theme.tokens.spacing.xxl)}
        >
          <Typography
            variant="h2"
            marginBottom={(theme) => (isMobileView ? theme.tokens.spacing.md : theme.tokens.spacing.xl)}
            fontSize={isMobileView ? "1.875rem" : "3rem"}
          >
            <FormattedMessage defaultMessage="Get 24/7 exceptional care" />
          </Typography>
          <Grid container spacing={0}>
            {isUnderXLScreenView ? (
              gridItemData.map((item, index) => (
                <Grid key={index} container item paddingBottom={(theme) => theme.tokens.spacing.lg}>
                  <Stack width="100%" gap={(theme) => theme.tokens.spacing.lg}>
                    <Stack direction="row" gap={(theme) => theme.tokens.spacing.md}>
                      <CircleImage isMobileView={isMobileView} src={item.imgSrc} hexcode={item.imgHexCode} />
                      <Stack gap={(theme) => theme.tokens.spacing.xs}>
                        <Typography variant="h4" component="h3" fontSize={isMobileView ? "1rem" : "1.75rem"}>
                          {intl.formatMessage(item.title)}
                        </Typography>
                        <Typography fontSize={isMobileView ? "0.813rem" : "1rem"}>
                          {intl.formatMessage(item.description)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider sx={{ borderBlockEndWidth: "medium", color: "#2D2D2D" }} />
                  </Stack>
                </Grid>
              ))
            ) : (
              <Grid container spacing={6}>
                {gridItemData.map((item, index) => (
                  <Grid key={index} item xs={4}>
                    <CircleImage isMobileView={isMobileView} src={item.imgSrc} hexcode={item.imgHexCode} />
                  </Grid>
                ))}
                {gridItemData.map((item, index) => (
                  <Grid key={index} item xs={4}>
                    <Typography variant="h4" component="h3" fontSize={"1.75rem"}>
                      {intl.formatMessage(item.title)}
                    </Typography>
                  </Grid>
                ))}
                {gridItemData.map((item, index) => (
                  <Grid key={index} item xs={4}>
                    <Typography fontSize={"1rem"}>{intl.formatMessage(item.description)}</Typography>
                  </Grid>
                ))}
                {gridItemData.map((item, index) => (
                  <Grid key={index} item xs={4}>
                    <Divider sx={{ borderBlockEndWidth: "medium", color: (theme) => theme.palette.text.secondary }} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Stack>
      </DigitalFrontDoorSectionLayout>
    </Box>
  )
}
