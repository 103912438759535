import actionTypes from "actions/actionTypes"
import { reportError } from "utils/ErrorReporting"
import { createRequestAction } from "./shared"
import { adminCarrotClient } from "utils/CarrotClient"
const client = adminCarrotClient

export const checkErrorForSessionExpired = (error, dispatch) => {
  if (error.errorCode === "INVALID_CREDENTIALS") {
    dispatch({
      type: actionTypes.SESSION_EXPIRED
    })
    return true
  }
  return false
}

export const updatePersonalEmails = (personalEmail, partnerEmail) => async (dispatch) => {
  try {
    await client.updateUser({
      personalEmail,
      partnerEmail
    })
  } catch (error) {
    error.message = "Error updating personal emails: " + error.message
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_PERSONAL_EMAILS_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_PERSONAL_EMAILS_SUCCESS,
    response: {
      personalEmail,
      partnerEmail
    }
  })
}

export const updateDateOfBirth = (dateOfBirth) => async (dispatch) => {
  try {
    await client.updateUser({
      dateOfBirth
    })
  } catch (error) {
    reportError(error)
    dispatch({
      type: actionTypes.UPDATE_DATE_OF_BIRTH_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.UPDATE_DATE_OF_BIRTH_SUCCESS,
    response: {
      dateOfBirth
    }
  })
}

export const createCardholder = (address1, address2, city, state, zip) => async (dispatch) => {
  await client.createCardholder(address1, address2, city, state, zip)

  dispatch({
    type: actionTypes.UPDATE_ADDRESS_SUCCESS,
    response: {
      address1,
      address2,
      city,
      state,
      zip
    }
  })
}

export const activateCard = (employeeId) => async (dispatch) => {
  dispatch({
    type: actionTypes.ACTIVATE_CARD_REQUEST
  })

  try {
    await client.activateCard()
  } catch (error) {
    error.message =
      `Card activation failed, error while activating card for employeeId: ${employeeId}: ` + error.message
    reportError(error)

    dispatch({
      type: actionTypes.ACTIVATE_CARD_FAILURE
    })
    return
  }

  dispatch({
    type: actionTypes.ACTIVATE_CARD_SUCCESS
  })
}

export const fetchCardDetails = createRequestAction("FETCH_VIRTUAL_CARD_DETAILS", client.getCardDetails.bind(client))
