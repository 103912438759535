import React, { useContext } from "react"
import { FlexContainer, Divider, SimpleGrid, Image } from "@carrotfertility/carotene"
import { ExpertBio } from "components/read/shared/ExpertBioMapper"
import { FormattedMessage } from "react-intl"
import { Link, Typography } from "@carrotfertility/carotene-core"
import {
  ExpertBioModalContext,
  ExpertBioModalContextProvider,
  ExpertBioModal
} from "components/expert-bios/expert-bio-modal"

const ExpertElement = ({ expertBio }: { expertBio: ExpertBio }): JSX.Element => {
  const { id, slug, headshot, name } = expertBio
  const { openExpertBioModal } = useContext(ExpertBioModalContext)

  return (
    <FlexContainer gap="small">
      <Image src={headshot?.file?.url} altText={""} height="60px" width="60px" sx={{ borderRadius: "8px" }} />
      <FlexContainer direction="column">
        <Typography variant="h6">{name}</Typography>
        <Link
          id="moderator-textlink"
          component="button"
          textAlign="start"
          data-expert-id={`${slug}-${id}`}
          color="primary"
          onClick={() => openExpertBioModal({ expertBio })}
        >
          <FormattedMessage defaultMessage="About the expert" />
        </Link>
      </FlexContainer>
    </FlexContainer>
  )
}

export const Moderators = ({ moderators }: { moderators: ExpertBio[] }): JSX.Element => {
  return (
    <ExpertBioModalContextProvider>
      <FlexContainer direction="column" gap="large">
        <FlexContainer direction="row" alignItems="center" gap="small">
          <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
            <FormattedMessage defaultMessage="Meet our facilitators" />
          </Typography>
          <Divider sx={{ maxInlineSize: "600px", inlineSize: "100%" }} color="black10" />
        </FlexContainer>
        <FlexContainer>
          <SimpleGrid columns={[1, 1, 2, 2]} spacingY={["large"]} spacingX="huge">
            {moderators?.map((moderator) => (
              <ExpertElement key={moderator.id} expertBio={moderator} />
            ))}
          </SimpleGrid>
        </FlexContainer>
      </FlexContainer>
      <ExpertBioModal />
    </ExpertBioModalContextProvider>
  )
}
