import { Box, Button, Link, Stack, Theme, Typography } from "@carrotfertility/carotene-core"
import React from "react"
import { DigitalFrontDoorSectionLayout } from "../components/DigitalFrontDoorSectionLayout"
import { FormattedMessage, useIntl } from "react-intl"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"

const US_SPANISH_PDF_URL =
  "https://assets.ctfassets.net/cwy2wizp0qlf/3IdD9oAyH2VzNlGbyEq0Jd/3db0905b2537439a65462d32b8b98fc5/Instrucciones_para_el_proveedor_-_Carrot_Rx.pdf"
const ENGLISH_PDF_URL =
  "https://assets.ctfassets.net/cwy2wizp0qlf/3IdD9oAyH2VzNlGbyEq0Jd/03338f21f57b2f7261f057c5e08527c7/Carrot_Rx_-_Provider_instructions.pdf"

export function StartSavingWithCarrotRxSection() {
  const { locale } = useIntl()

  const providerInstructionsPdf = locale === "es-US" ? US_SPANISH_PDF_URL : ENGLISH_PDF_URL

  const { isMobileView, isAboveXLScreenView } = useDigitalFrontDoorBreakpointViews()

  const responsiveGapForSubsections = isMobileView
    ? (theme: Theme) => theme.tokens.spacing.sm
    : (theme: Theme) => theme.tokens.spacing.xs

  const responsiveH3FontSize = isMobileView ? "1.125rem" : "1.5rem"
  const responsiveBodyFontSize = isMobileView ? "0.813rem" : "1rem"

  const baseUrl = window.location.origin

  const handleOnclick = () => {
    const pharmacyURL = `${baseUrl}/your-benefit-guide/pharmacy`
    window.open(pharmacyURL, "_blank")
  }

  return (
    <Box id="start-saving-with-carrot-rx" className="start-saving-with-carrot-rx-section">
      <DigitalFrontDoorSectionLayout backgroundColor={"#F1EFEB"}>
        <Stack
          direction={isAboveXLScreenView ? "row" : "column"}
          gap={isMobileView ? (theme) => theme.tokens.spacing.lg : "6.375rem"}
          justifyContent="space-between"
          paddingY={isMobileView ? (theme: Theme) => theme.tokens.spacing.xxl : "7.5rem"}
        >
          <Stack
            direction="column"
            gap={isMobileView ? (theme) => theme.tokens.spacing.lg : (theme) => theme.tokens.spacing.xxl}
          >
            <Typography id={"startSavingWithCarrotRx"} variant="h2" fontSize={isMobileView ? "1.75rem" : "2.5rem"}>
              <FormattedMessage defaultMessage="Start saving with Carrot Rx" />
            </Typography>
            <Stack gap={responsiveGapForSubsections}>
              <Typography variant="h3" fontSize={responsiveH3FontSize}>
                <FormattedMessage defaultMessage="1. Confirm your info" />
              </Typography>
              <Typography variant="body1" fontSize={responsiveBodyFontSize}>
                <FormattedMessage
                  defaultMessage="Confirm your phone number and date of birth in <link>account settings</link>."
                  values={{
                    link: (linkContent) => (
                      <Link id="account-settings-link" href={`${baseUrl}/account`} target="_blank" fontSize="inherit">
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Typography>
            </Stack>
            <Stack gap={responsiveGapForSubsections}>
              <Typography variant="h3" fontSize={responsiveH3FontSize}>
                <FormattedMessage defaultMessage="2. Tell your provider" />
              </Typography>
              <Typography variant="body1" fontSize={responsiveBodyFontSize}>
                <FormattedMessage defaultMessage="Ask your provider to send your prescriptions to Alto, Carrot Rx’s pharmacy partner." />
              </Typography>
              <Link
                id="provider-instructions-pdf-link"
                href={providerInstructionsPdf}
                target="_blank"
                fontSize={responsiveBodyFontSize}
              >
                <FormattedMessage defaultMessage="Download PDF with provider instructions" />
              </Link>
            </Stack>
            <Stack gap={responsiveGapForSubsections}>
              <Typography variant="h3" fontSize={responsiveH3FontSize}>
                <FormattedMessage defaultMessage="3. Place your order" />
              </Typography>
              <Typography variant="body1" fontSize={responsiveBodyFontSize}>
                <FormattedMessage
                  defaultMessage="When Carrot Rx receives a prescription from your provider, Alto will send you a text message. Follow the link provided to order your prescriptions. {br}{br} Carrot member discounts will be applied automatically."
                  values={{
                    br: <br />
                  }}
                />
              </Typography>
            </Stack>
            <Button
              onClick={handleOnclick}
              variant="outlined"
              fullWidth={false}
              sx={{
                inlineSize: "fit-content",
                background: (theme) => theme.palette.background.paper,
                color: (theme) => theme.palette.text.primary,
                fontSize: responsiveBodyFontSize,
                fontWeight: 325
              }}
              id="learn-more-about-your-pharmacy-benefit-button"
            >
              <FormattedMessage defaultMessage="Learn more about your pharmacy benefit" />
            </Button>
          </Stack>
          <Box
            component="img"
            src={require("../resources/images/carrot-rx-delivery.jpg")}
            alt=""
            width={isAboveXLScreenView ? "30.375rem" : "auto"}
            height={isMobileView ? "14.5rem" : "31rem"}
            borderRadius="0 2rem 0 0"
            sx={{ objectFit: "cover" }}
          />
        </Stack>
      </DigitalFrontDoorSectionLayout>
    </Box>
  )
}
