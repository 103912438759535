import React from "react"
import { FlexContainer, H3, Stack, Text } from "@carrotfertility/carotene"
import { CircleImage } from "../../../views/atoms/images/CircleImage"
import { FormattedMessage } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"

const CarrotRxMedicationCard = (): JSX.Element => (
  <FlexContainer data-testid="carrot-rx-medication-card" sx={{ backgroundColor: "#F0F0F0", borderRadius: "6px" }}>
    <Stack sx={{ margin: "30px 15px 15px 25px" }}>
      <CircleImage iconName="icn-carrot-rx" backgroundColor={"black-10"} />
    </Stack>
    <FlexContainer direction="column">
      <H3 sx={{ paddingBlockStart: "25px", paddingBlockEnd: "10px" }}>
        <FormattedMessage defaultMessage="Carrot Rx required" />
      </H3>
      <Text sx={{ paddingBlockEnd: "25px", paddingInlineEnd: "15px" }}>
        <FormattedMessage
          defaultMessage="All medications must be filled through <link>Carrot Rx</link> to be eligible."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={"/your-benefit-guide/pharmacy"} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Text>
      <Text sx={{ paddingBlockEnd: "25px", paddingInlineEnd: "15px" }}>
        <FormattedMessage
          defaultMessage="If you qualify for an <link>exception</link>, please specify which one in the comment box on the last screen before you submit your expense."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={"/your-benefit-guide/pharmacy"} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Text>
    </FlexContainer>
  </FlexContainer>
)

export default CarrotRxMedicationCard
