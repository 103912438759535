import { useMemo } from "react"
import { useGetRoutingInfo } from "../shared/useQueryRoutingInfo"
import useCarrotPlan from "./useCarrotPlan"
import useGetMemberBenefit from "./useGetMemberBenefit"
import { useGetMostRecentlyCompletedRouting } from "./useGetMostRecentlyCompletedRouting"
import { useGetRegistrationJourney } from "./useGetRegistrationJourney"
import { useEnabledJourneyOptions, isJourneySupported } from "./useJourneyOptions"

type UseJourney = {
  journeyDataIsLoaded: boolean
  isJourneyComplete: boolean
  resolvedJourney: string | null
  memberHasJourneyType: boolean
}

export function useJourney(): UseJourney {
  const { data: mostRecentCompleteRouting, isLoading: isMostRecentCompleteRoutingLoading } =
    useGetMostRecentlyCompletedRouting()
  const { data: routingInfo, isLoading: isRoutingInfoLoading } = useGetRoutingInfo()
  const { data: carrotPlan, isLoading: isCarrotPlanLoading } = useCarrotPlan()
  const { data: registrationJourneyResult, isLoading: isRegistrationJourneyLoading } = useGetRegistrationJourney()
  const { data: memberBenefit, isLoading: isMemberBenefitLoading } = useGetMemberBenefit()
  const { journeyOptions } = useEnabledJourneyOptions(memberBenefit)
  const memberHasJourneyType = Boolean(
    mostRecentCompleteRouting?.journey || routingInfo?.journey || registrationJourneyResult
  )
  const isJourneyComplete = carrotPlan?.journeyCompleteTimestampUtc != null

  const journeyDataIsLoaded =
    !isMemberBenefitLoading &&
    !isRoutingInfoLoading &&
    !isRegistrationJourneyLoading &&
    !isCarrotPlanLoading &&
    !isMostRecentCompleteRoutingLoading

  const resolvedJourney = useMemo(() => {
    if (!journeyDataIsLoaded) return null

    let journeySelection = mostRecentCompleteRouting?.journey
    journeySelection ??= routingInfo?.journey
    journeySelection ??= registrationJourneyResult?.journey

    if (isJourneySupported({ journey: journeySelection, options: journeyOptions })) {
      return journeySelection
    }

    return null
  }, [
    journeyDataIsLoaded,
    routingInfo?.journey,
    registrationJourneyResult?.journey,
    journeyOptions,
    mostRecentCompleteRouting?.journey
  ])

  return {
    journeyDataIsLoaded,
    isJourneyComplete,
    memberHasJourneyType,
    resolvedJourney
  }
}
