import React, { ReactNode } from "react"
import {
  Link as TextLink,
  Stack,
  CloseIcon,
  IconButton,
  Box,
  DialogTitle,
  Typography,
  DialogContentText,
  Dialog,
  DialogContent,
  useTheme
} from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { useUserRole } from "../partner-access/hooks/usePartnerAccess"
import { employeeOnlyHeaderText, employeeOnlyText, employeeOnlyLink } from "./employeeOnlySpaceContent"
import { FormattedMessage, useIntl } from "react-intl"

export const EmployeeOnlyModalContainer = ({
  onClose,
  open = true,
  ...props
}: EmployeeOnlyModalContainerProps): JSX.Element => {
  const theme = useTheme()
  const { isPartnerAccessingAccount, isUserRoleLoading } = useUserRole()
  const intl = useIntl()

  if (isUserRoleLoading) {
    return null
  }

  if (isPartnerAccessingAccount) {
    return (
      <Dialog open={open}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <IconButton
            aria-label={intl.formatMessage({
              defaultMessage: "close"
            })}
            onClick={onClose}
          >
            <CloseIcon fontSize={"medium"} color="secondary" />
          </IconButton>
        </Box>

        <DialogTitle>{intl.formatMessage(employeeOnlyHeaderText)}</DialogTitle>
        <DialogContent sx={{ paddingBlockEnd: theme.spacing(theme.tokens.spacing.xs) }}>
          <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
            <Box>
              <DialogContentText>{intl.formatMessage(employeeOnlyText)}</DialogContentText>
            </Box>
            <Typography>
              <FormattedMessage
                defaultMessage="Questions? <link>Contact us.</link>"
                values={{
                  link: (linkContent) => (
                    <TextLink component={RouterLink} to={employeeOnlyLink}>
                      {linkContent}
                    </TextLink>
                  )
                }}
              />
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }

  return <>{props.children}</>
}

type EmployeeOnlyModalContainerProps = {
  children: ReactNode
  onClose: () => void
  open?: boolean
}

export default EmployeeOnlyModalContainer
