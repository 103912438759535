import React, { FC, ReactNode } from "react"
import styled from "styled-components"
import { Spacer } from "components/views/atoms/Atoms"
import { BodySmall } from "components/views/atoms/Bodies"
// @ts-ignore
import colors from "css/variables/_colors.scss"
import { Label } from "components/views/atoms/Label"
import { PiiText } from "services/tracking"

const DetailsList = styled.div`
  inline-size: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 16px;
  border-block-end: 1px solid ${colors.mint};
  border-block-start: 1px solid ${colors.mint};
`

const StyledDetailsListItem = styled.div`
  padding: 8px 0;
  border-block-end: 1px solid ${colors.mintAlternate};
  &:last-child {
    border-block-end: none;
  }
`

type DetailsListItemProps = {
  header: string
  body: ReactNode
}

const DetailsListItem: FC<DetailsListItemProps> = ({ header, body }) => (
  <StyledDetailsListItem>
    <Label>{header}</Label>
    <Spacer height={0.125} />
    <BodySmall color="black-80">
      <PiiText>{body}</PiiText>
    </BodySmall>
  </StyledDetailsListItem>
)

export { DetailsList, DetailsListItem }
