import React from "react"
import { Box, Link, Typography } from "@carrotfertility/carotene-core"
import { IlloCircleCarrotCards } from "components/views/atoms/Illustrations.js"
import { FeatureGate } from "utils/FeatureGate"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "utils/Paths"

export function CardRequestPending() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBlockStart: (theme) => theme.tokens.spacing.xl
        }}
      >
        <IlloCircleCarrotCards />
      </Box>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="We’re preparing your virtual card." />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="You will receive an email and SMS confirmation when your card is ready." />
      </Typography>
      <FeatureGate filter={(c) => c.isSupportCenterEnabled}>
        <Link
          sx={{ display: "inline-block", marginBlockStart: (theme) => theme.tokens.spacing.md }}
          href={DbgPaths.CARROT_CARD}
          target="_blank"
        >
          <FormattedMessage defaultMessage="Learn more about Carrot Card" />
        </Link>
      </FeatureGate>
    </div>
  )
}
