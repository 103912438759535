import { StatusCodes } from "http-status-codes"
// @ts-expect-error TS7016
import { v4 as uuid } from "uuid"
import ResponseError from "../types/ResponseError"
import { reportError } from "../utils/ProviderFinderErrorReporting"
import { AppointmentRequest } from "../types/RequestAppointmentTypes"
import { ProviderRequest } from "../types/ProviderRequest"
import ProviderFinderClientError from "./CarrotBenefitClient"

export class CarrotPartnerBookingApiClient {
  baseUrl: string
  handleSessionExpiration: () => void

  constructor(baseUrl: string, handleSessionExpiration: () => void) {
    this.baseUrl = baseUrl
    this.handleSessionExpiration = handleSessionExpiration
  }

  createJsonHeaders(correlationId: string): Headers {
    const headers = new Headers()
    headers.append("X-Request-ID", correlationId)
    headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
    headers.append("Content-Type", "application/json")
    return headers
  }

  handleError(correlationId: any, error: any): void {
    error.correlationId = correlationId

    if (error instanceof ResponseError && error.statusCode === StatusCodes.UNAUTHORIZED) {
      this.handleSessionExpiration()
      return
    }

    reportError(
      new ProviderFinderClientError("Failed to submit Carrot Partner appointment request", {
        cause: error,
        correlationId
      })
    )

    throw error
  }

  async postAppointmentRequest(appointmentRequest: AppointmentRequest): Promise<void> {
    const correlationId = uuid()
    try {
      const url = new URL(`${this.baseUrl}/api/v2/provider-finder/request-appointment`)
      const response = await fetch(url, {
        method: "POST",
        headers: this.createJsonHeaders(correlationId),
        credentials: "include",
        body: JSON.stringify(appointmentRequest)
      })
      if (response.status !== StatusCodes.OK) {
        this.handleError(correlationId, new ResponseError(response, correlationId))
      }
    } catch (error) {
      this.handleError(correlationId, error)
    }
  }

  async postProviderRequest(providerRequest: ProviderRequest): Promise<void> {
    const correlationId = uuid()
    try {
      const url = new URL(`${this.baseUrl}/api/v2/provider-finder/find-provider-recommendation`)
      const response = await fetch(url, {
        method: "POST",
        headers: this.createJsonHeaders(correlationId),
        credentials: "include",
        body: JSON.stringify(providerRequest)
      })

      if (response.status !== StatusCodes.OK) {
        this.handleError(correlationId, new ResponseError(response, correlationId))
      }
    } catch (error) {
      this.handleError(correlationId, error)
    }
  }
}
