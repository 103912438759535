import { fetchUserInfo, updateUserInfo } from "actions/userActions"
import { useLDClient } from "launchdarkly-react-client-sdk"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCompanyInfo } from "reducers/companyInfo"
import { getBenefitStarted } from "../../../derivedSelectors"
import { getUserInfo } from "reducers/userInfo"
import { UserInfo } from "lib/carrot-api/types/UserInfo"
import { LaunchDarklyUser } from "../../../types/launchDarklyUser"

const removePropertyFromObject = (property: string, obj: Record<string, any>): Record<string, any> => {
  const { [property]: _, ...rest } = obj
  return rest
}

/*
  `UserContext` was previously untyped, which led to bugs where property values were used unexpectedly. Specifying a
  "correct" type is very challenging due to the legacy redux code in src/reducers/companyInfo.ts not fully matching
  the definition in src/lib/carrot-api/types/UserInfo.ts. As a stop-gap, this `IUserInterface` specifies the type of
  specific properties where we've encountered issues, as well as `[key: string]: any` to allow all other properties.

  If/when the redux types are cleaned up, `IUserContext` should be removed and replaced with the appropriate type. In
  the meantime, we can add typed properties here to enforce how `UserContext` is used as needed.
 */
interface IUserContext {
  [key: string]: any
  locale?: string
}

const UserContext = React.createContext<IUserContext>(null)

export const useCurrentUser = () => React.useContext(UserContext)

// @ts-expect-error TS7031
export function UserContextProvider({ children }): JSX.Element {
  const dispatch = useDispatch()
  // We don't want preferredLocale to be used, so we remove it from the userInfo object
  const userInfo: Omit<Partial<UserInfo>, "preferredLocale"> = removePropertyFromObject(
    "preferredLocale",
    useSelector(getUserInfo)
  )
  const company = useSelector(getCompanyInfo)
  const benefitStarted = useSelector(getBenefitStarted)
  const ldClient = useLDClient()

  const loadLDFlagsForUser: () => Promise<boolean> = React.useCallback(async () => {
    if (ldClient && userInfo.email) {
      await ldClient.waitUntilReady()

      const ldUser: LaunchDarklyUser = {
        key: userInfo.email,
        email: userInfo.email,
        custom: {
          companyId: company.companyId,
          isPartner: false,
          isEnrolled: true,
          isEngaged: !userInfo.isInAppLockdown
        }
      }

      if (company.parentCompanyId) {
        // @ts-expect-error TS7053
        ldUser.custom["parentCompanyId"] = company.parentCompanyId
      }

      await ldClient.identify(ldUser)
      return true
    }
    return false
  }, [ldClient, userInfo.email, userInfo.isInAppLockdown, company.companyId, company.parentCompanyId])

  function refreshUserInfo() {
    return dispatch(fetchUserInfo())
  }

  const getFirstName = React.useCallback(
    (): string => userInfo?.preferredName || userInfo?.firstName,
    [userInfo?.firstName, userInfo?.preferredName]
  )

  return (
    <UserContext.Provider
      value={{
        refreshUserInfo,
        // @ts-expect-error TS7019
        updateUserInfo: (...args) => dispatch(updateUserInfo(...args)),
        company,
        benefitStarted,
        getFirstName,
        loadLDFlagsForUser,
        ...userInfo
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
