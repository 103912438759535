import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Steps } from "../workflow/steps"
import { BenefitEnrollmentFlowModalBackButton, BenefitEnrollmentModalContinueButton } from "../shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import { FormTextField } from "@carrotfertility/carotene-core-x"
import { carrotClient } from "utils/CarrotClient"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"

const CurrentFieldName = "medicareId"
const CurrentStepName = Steps.MEDICARE_ID

function MedicareIdStep(): JSX.Element {
  const intl = useIntl()

  const formMethods = useForm()
  const { handleSubmit } = formMethods
  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (input) => {
    await carrotClient.addMedicareEnrollment(input["medicareId"])
    setNextStep(CurrentStepName, "")
  })

  const { setNextStep } = useStateMachine(CurrentStepName)

  function validateMedicareId(medicareId: string): string | boolean {
    const trimmedMedicareId = medicareId.trim()
    const alphanumericRegex = /^[a-zA-Z0-9]+$/
    if (
      trimmedMedicareId.length < 10 ||
      trimmedMedicareId.length > 15 ||
      trimmedMedicareId.length === 0 ||
      !alphanumericRegex.test(trimmedMedicareId)
    ) {
      return intl.formatMessage({
        defaultMessage:
          "Your Medicare ID can be found on your Medicare card. Please enter only the 10–15 letters and numbers without dashes."
      })
    }
    return true
  }

  return (
    <FormProvider {...formMethods}>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <BenefitEnrollmentFlowModalBackButton />
      </Box>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="What is your Medicare ID?" />
      </Typography>
      <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage
            defaultMessage={
              "We are required to collect and share this information with the Centers for Medicare & Medicaid Services. " +
              "It enables Medicare to correctly pay for health insurance benefits by determining primary versus secondary payer responsibility."
            }
          />
        </Typography>
      </Box>
      <Box paddingBottom="8rem">
        <FormTextField
          name={CurrentFieldName}
          helperText={intl.formatMessage({
            defaultMessage:
              "Your Medicare ID can be found on your Medicare card. Please enter only the 10–15 letters and numbers without dashes."
          })}
          registerOptions={{
            validate: validateMedicareId,
            required: intl.formatMessage({ defaultMessage: "Required" })
          }}
        />
      </Box>
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

export { MedicareIdStep }
