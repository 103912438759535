import React, { useContext, useState } from "react"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import CarrotClient from "carrot-api"
import { Alert, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Form, FormCheckbox } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import Settings from "utils/CarrotConfig"
import { Steps } from "../workflows/steps"
import { FormattedMessage, useIntl } from "react-intl"
import { TtcPaths } from "../../../utils/Paths"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const DefaultVerifyPhoneNumberState = {
  isInError: false
}

const StripeUserAgreement = () => {
  const { isUsaCompany } = useContext(RequestCardContext)
  const [state, setState] = useState(DefaultVerifyPhoneNumberState)
  const theme = useTheme()
  const intl = useIntl()

  const { canGoBack, send } = useStateMachine()

  const onSubmission = async () => {
    try {
      setState({
        ...state,
        isInError: false
      })

      await client.requestCard()

      send("")
    } catch (e) {
      setState({ ...state, isInError: true })
    }
  }

  return (
    <Form onSubmit={onSubmission} defaultValues={{ stripeUserAgreementCheckbox: false }}>
      <RequestCardWrapper
        goBackToStepName={Steps.CONFIRM_PHONE_NUMBER}
        showBackButton={canGoBack}
        showContinueButton
        disableContinue={"stripeUserAgreementCheckbox"}
        sectionNum={isUsaCompany ? 3 : 2}
      >
        <div>
          <Typography variant="h2">
            <FormattedMessage defaultMessage="Review terms" />
          </Typography>
          <Stack spacing={theme.spacing(theme.tokens.spacing.xl)}>
            <Typography variant="body1">
              <FormattedMessage defaultMessage="To receive a Carrot Card, you need to read and accept the card issuer's terms on card use, security, data privacy, and more." />
            </Typography>
            <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
              <FormCheckbox
                name={"stripeUserAgreementCheckbox"}
                label={intl.formatMessage(
                  {
                    defaultMessage:
                      "I have read and accept the <link>authorized user terms</link> and <link2>E-SIGN disclosure</link2>."
                  },
                  {
                    link: (linkContent) => (
                      <Link
                        href="https://stripe.com/legal/issuing/celtic-authorized-user-terms"
                        target="_blank"
                        color="inherit"
                      >
                        {linkContent}
                      </Link>
                    ),
                    link2: (linkContent) => (
                      <Link href="https://stripe.com/legal/e-sign-disclosure" target="_blank" color="inherit">
                        {linkContent}
                      </Link>
                    )
                  }
                )}
                required={true}
              />
            </Stack>
            {state.isInError && (
              <Alert severity="error">
                <FormattedMessage
                  defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
                  values={{
                    link: (linkContent) => (
                      <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Alert>
            )}
          </Stack>
        </div>
      </RequestCardWrapper>
    </Form>
  )
}

export { StripeUserAgreement }
