import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "components/views/molecules/CarrotCardModuleContainer"
import { CenteredLoadingIndicator } from "components/views/molecules/Molecules"
import { Box } from "@carrotfertility/carotene-core"

const CarrotCardLoadingModule: FC<CarrotCardModuleSharedProps> = () => (
  <Box
    borderRadius={(theme) => theme.tokens.borderRadius.md}
    padding={(theme) => theme.tokens.spacing.lg}
    sx={(theme) => ({ backgroundColor: theme.palette.background.default })}
  >
    <CenteredLoadingIndicator />
  </Box>
)

export { CarrotCardLoadingModule }
