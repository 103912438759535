import React from "react"
import { FormControlLabel, Checkbox, checkboxClasses } from "@carrotfertility/carotene-core"
import { FormCheckboxGroup, FormCheckBoxGroupProps, useWatch } from "@carrotfertility/carotene-core-x"

interface RoutingFlowCheckboxGroupProps extends FormCheckBoxGroupProps {
  maxSelections?: number
}

export function RoutingFlowCheckboxGroup({
  name,
  options,
  maxSelections,
  ...otherProps
}: RoutingFlowCheckboxGroupProps): JSX.Element {
  const selectedOptions = useWatch({ name })
  return (
    <FormCheckboxGroup
      {...{ name, options }}
      {...otherProps}
      renderItem={({ formControlLabelProps, checkboxProps }) => (
        <FormControlLabel
          key={checkboxProps.value as string}
          {...formControlLabelProps}
          sx={(theme) => ({
            padding: theme.spacing(theme.tokens.spacing.sm),
            borderRadius: theme.tokens.borderRadius.md,
            borderStyle: "solid",
            borderWidth: "2px",
            inlineSize: "fit-content",
            alignItems: "center",
            borderColor: checkboxProps.checked ? theme.palette.secondary.main : "transparent",
            [`:not(.${checkboxClasses.disabled}):hover`]: {
              bgcolor: theme.palette.secondary.light
            },
            [`&.${checkboxClasses.disabled}`]: {
              borderColor: checkboxProps.checked ? theme.palette.action.disabled : "transparent"
            }
          })}
          control={
            <Checkbox
              {...checkboxProps}
              disabled={maxSelections && selectedOptions?.length >= maxSelections && !checkboxProps.checked}
            />
          }
        />
      )}
    />
  )
}
