import React, { FC } from "react"
import { Typography, Stack, Link, InternalIcon } from "@carrotfertility/carotene-core"
import { DbgPaths } from "../../../../utils/Paths"
import { FormattedMessage } from "react-intl"

export type ExternalDeductibleInformationalProps = {
  costShare: boolean
}

const ExternalDeductibleInformational: FC<ExternalDeductibleInformationalProps> = ({ costShare }) => {
  return (
    <>
      <Stack display="flex" gap={(theme) => theme.tokens.spacing.lg}>
        <Typography>
          <FormattedMessage
            defaultMessage="You need to meet your health insurance deductible before you can use Carrot funds for eligible <link>qualified medical expenses</link>."
            values={{
              link: (linkContent) => (
                <Link href={DbgPaths.QUALIFIED_MEDICAL_EXPENSES} color={"inherit"} size={"medium"} target="_blank">
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>

        <Typography>
          {costShare ? (
            <FormattedMessage defaultMessage="Funds you use may also be subject to bank fees, taxes, cost sharing, and other plan details." />
          ) : (
            <FormattedMessage defaultMessage="Funds you use may also be subject to bank fees, taxes, and other plan details." />
          )}
        </Typography>
      </Stack>

      <Stack paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Link
          href={"/your-benefit-guide/carrot-funds/deductibles"}
          target={"_blank"}
          endIcon={<InternalIcon fontSize="small" color="primary" />}
        >
          <FormattedMessage defaultMessage="Learn how your insurance deductible works with Carrot" />
        </Link>
      </Stack>
    </>
  )
}

export { ExternalDeductibleInformational }
