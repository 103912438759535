import CarrotClient from "../../../lib/carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { ReimbursementDetails } from "../types/ReimbursementDetails"
import { HttpErrors } from "utils/HttpErrors"
import { useAuthentication } from "components/context/authentication/AuthenticationProvider"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const ENDPOINT = "GetReimbursementDetails"

const loadDetails = async (handleSessionExpiration: () => void): Promise<ReimbursementDetails> => {
  //Because the client returns a new class object react-query sees every request to the BE as a data change
  //to avoid infinite re-rendering and reloading we need to strip the data out of the class wrapper
  try {
    const details = await client.getReimbursementDetails()
    return { ...details }
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED) {
      handleSessionExpiration()
    }
    return null
  }
}

export const getReimbursementDetailsCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useGetReimbursementDetails(): UseQueryResult<ReimbursementDetails, ResponseError> {
  const { handleSessionExpiration } = useAuthentication()
  return useQuery<ReimbursementDetails, ResponseError>(getReimbursementDetailsCacheKey(), () =>
    loadDetails(handleSessionExpiration)
  )
}

export function useCreateOverride(): (attributes: ReimbursementDetails) => void {
  const queryClient = useQueryClient()
  return (attributes: ReimbursementDetails) => {
    queryClient.setQueryDefaults(getReimbursementDetailsCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getReimbursementDetailsCacheKey(), attributes)
  }
}
