import React from "react"
import { useMapViewContext } from "../../context/MapViewContext"
import { ProviderFinderParam, useStringSearchParam } from "../../utils/providerFinderSearchUrl"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Button, RightIcon, Typography, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

type SwitchViewButtonProps = {
  showMapView: boolean
  switchViewHandler: () => void
}

function SwitchViewButtonWithImage({ showMapView, switchViewHandler }: SwitchViewButtonProps): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const backgroundImageUrl = showMapView
    ? `url(${require(`../../resources/images/list-view-background.png`)})`
    : `url(${require(`../../resources/images/map-view-background.png`)})`
  return (
    <Button
      onClick={switchViewHandler}
      id={showMapView ? "switch-to-list-view-button" : "switch-to-map-view-button"}
      sx={{
        backgroundImage: backgroundImageUrl,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.tokens.borderRadius.md,
        borderColor: theme.palette.background.paper,
        inlineSize: "100%",
        paddingBlockStart: theme.spacing(theme.tokens.spacing.md),
        paddingBlockEnd: theme.spacing(theme.tokens.spacing.md),
        backgroundOrigin: "border-box",
        backgroundPosition: "left",
        backgroundSize: "320px",
        ":hover": {
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.primary.main
        },
        ":focus-visible": {
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.primary.main
        }
      }}
      aria-label={
        showMapView
          ? intl.formatMessage({ defaultMessage: "switch to list view" })
          : intl.formatMessage({ defaultMessage: "switch to map view" })
      }
    >
      <Box
        borderRadius={(theme) => theme.tokens.borderRadius.round}
        sx={{ backgroundColor: theme.palette.background.paper }}
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        paddingRight={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      >
        <Typography variant="button" color="secondary">
          {showMapView
            ? intl.formatMessage({ defaultMessage: "List View" })
            : intl.formatMessage({ defaultMessage: "Map View" })}
        </Typography>
      </Box>
    </Button>
  )
}

function TextOnlySwitchViewButton({ showMapView, switchViewHandler }: SwitchViewButtonProps): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Box padding={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
        <Typography variant="h6" paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <FormattedMessage defaultMessage="Display" />
        </Typography>
        <Button
          variant="text"
          color="secondary"
          onClick={switchViewHandler}
          id={showMapView ? "switch-to-list-view-button" : "switch-to-map-view-button"}
          sx={{
            inlineSize: "100%",
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.tokens.borderRadius.md,
            paddingBlockStart: theme.spacing(theme.tokens.spacing.md),
            paddingBlockEnd: theme.spacing(theme.tokens.spacing.md),
            justifyContent: "left",
            ":hover": {
              backgroundColor: theme.palette.background.paper,
              borderColor: theme.palette.primary.dark
            },
            ":focus-visible": {
              backgroundColor: theme.palette.background.paper,
              borderColor: theme.palette.primary.dark
            }
          }}
          aria-label={
            showMapView
              ? intl.formatMessage({ defaultMessage: "switch to list view" })
              : intl.formatMessage({ defaultMessage: "switch to map view" })
          }
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
          >
            {showMapView
              ? intl.formatMessage({ defaultMessage: "Switch to list view" })
              : intl.formatMessage({ defaultMessage: "Switch to map view" })}
            <RightIcon fontSize="small" />
          </Box>
        </Button>
      </Box>
    </Box>
  )
}

export function SwitchViewButton(): JSX.Element {
  const { showMapView, setShowMapView } = useMapViewContext()
  const theme = useTheme()
  const [, setradiusSearchParam] = useStringSearchParam(ProviderFinderParam.RadiusInKm)

  const switchViewHandler = (): void => {
    if (!showMapView) {
      setradiusSearchParam("") // set to anywhere in the country
    }
    setShowMapView(!showMapView)
  }

  return useMediaQuery(theme.breakpoints.up("lg")) ? (
    <SwitchViewButtonWithImage showMapView={showMapView} switchViewHandler={switchViewHandler} />
  ) : (
    <TextOnlySwitchViewButton showMapView={showMapView} switchViewHandler={switchViewHandler} />
  )
}
