import React from "react"
import { insuranceQuestions } from "./insuranceQuestions"
import { getPiiProps } from "services/tracking"
import {
  TableRow,
  TableCell,
  Typography,
  Box,
  IconButton,
  InfoIcon,
  Dialog,
  CloseIcon,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  useTheme
} from "@carrotfertility/carotene-core"
import { jsx } from "@emotion/react"
import { useToggle } from "utils/Hooks"
import { useIntl } from "react-intl"

export type InsurancePlanInformationRowData = {
  headerLabel: string
  memberData?: string
  partnerData?: string
  dialogText?: string[]
  dialogTitle?: string
  helperText?: string
}

export interface InsurancePlanInformationRowProps extends InsurancePlanInformationRowData {
  hasPartner: boolean
  insuranceExpired: boolean
}

// @ts-expect-error TS7031
const DialogModal = ({ onExit, dialogText, dialogTitle }): jsx.Element => {
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Dialog aria-labelledby="dialog-title" onClose={onExit} open={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onExit}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
        <DialogTitle
          id="dialog-title"
          component="h2"
          variant="h2"
          paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            display="flex"
            flexDirection="column"
            gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
          >
            {dialogText.map(
              (text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal, index: React.Key) => (
                <Typography key={index}>{text}</Typography>
              )
            )}
          </DialogContentText>
        </DialogContent>
      </Stack>
    </Dialog>
  )
}

const PlanInformationRow = (props: InsurancePlanInformationRowProps): JSX.Element => {
  const [showDialogModal, toggleDialogModal] = useToggle(false)

  const { headerLabel, memberData, partnerData, dialogText, dialogTitle, helperText, hasPartner, insuranceExpired } =
    props

  const textError =
    insuranceExpired && headerLabel === insuranceQuestions.WHEN_IS_YOUR_DEDUCTIBLE_PERIOD["defaultMessage"]

  const intl = useIntl()

  return (
    <TableRow>
      <TableCell variant="head" component="th" scope="row" sx={{ inlineSize: "24rem" }}>
        {headerLabel}
        {dialogText && (
          <IconButton
            aria-label={intl.formatMessage({ defaultMessage: "Learn more" })}
            size="small"
            onClick={toggleDialogModal}
          >
            <InfoIcon />
          </IconButton>
        )}
        <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
          {helperText}
        </Typography>
        {showDialogModal && (
          <DialogModal onExit={toggleDialogModal} dialogText={dialogText} dialogTitle={dialogTitle} />
        )}
      </TableCell>
      <TableCell {...getPiiProps()} sx={(theme) => (textError ? { color: theme.palette.error.main } : {})}>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        {memberData ? memberData : "--"}
      </TableCell>
      {hasPartner && (
        <TableCell {...getPiiProps()} sx={(theme) => (textError ? { color: theme.palette.error.main } : {})}>
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          {partnerData ? partnerData : "--"}
        </TableCell>
      )}
    </TableRow>
  )
}

export default PlanInformationRow
