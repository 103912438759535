import React from "react"
import { Spacer } from "./Atoms"
import { NotSmall, Small } from "./Responsive"
import { Body, BodyLarge } from "./Bodies"
import { ArrowLink, ArrowLinkArrow } from "./links/ArrowLink"
import { H1, H2 } from "@carrotfertility/carotene"
import { MaybeLink } from "./links/MaybeLink"
import { useHistory } from "react-router-dom"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { IconButton, LeftIcon } from "@carrotfertility/carotene-core"

const SwitchH1ToH2 = ({ children }) => (
  <>
    <NotSmall>
      <H1>{children}</H1>
    </NotSmall>
    <Small>
      <H2>{children}</H2>
    </Small>
  </>
)
const SwitchH2ToBodyLarge = ({ children, ...otherProps }) => (
  <>
    <NotSmall>
      <H2 {...otherProps}>{children}</H2>
    </NotSmall>
    <Small>
      <BodyLarge color="black-80" {...otherProps}>
        {children}
      </BodyLarge>
    </Small>
  </>
)

const SpacerSwitcher = () => (
  <>
    <NotSmall>
      <Spacer height={1.5} />
    </NotSmall>
    <Small>
      <Spacer height={1} />
    </Small>
  </>
)

const LeftArrow = ({ onClick = () => {}, to = null }) => {
  return (
    <MaybeLink to={to} onClick={onClick} className="dib pointer">
      <button
        data-testid="left-arrow"
        className="pointer ba b--white-025 bg-semi-transparent-white hover-bg-very-light-carrot-orange br-100 flex items-center justify-center"
        type="button"
        style={{
          height: "2rem",
          width: "2rem"
        }}
      >
        <LeftBackArrowImage />
      </button>
    </MaybeLink>
  )
}

export const LeftArrowAndSpace = ({ onBack }) => (
  <>
    <LeftArrow onClick={onBack} />
    <div className="dn db-ns">
      <Spacer height={1.5} />
    </div>
    <div className="no-pointer-events db dn-ns" style={{ marginTop: "-26px" }} />
  </>
)

const LeftBackArrowImage = () => {
  const intl = useIntl()
  return (
    <>
      <img
        src="/images/left-carrot-medium.svg"
        alt={intl.formatMessage({ defaultMessage: "carrot", description: "left back arrow alt text" })}
        style={{
          marginLeft: "-2px",
          width: "1rem",
          height: "1rem",
          position: "absolute"
        }}
      />
    </>
  )
}

const BackArrow = ({ onClick }) => {
  const history = useHistory()
  const intl = useIntl()
  return (
    <IconButton
      sx={{ inlineSize: "fit-content" }}
      size={"small"}
      aria-label={intl.formatMessage({ defaultMessage: "Back" })}
      color={"secondary"}
      onClick={() => {
        onClick ? onClick() : history.goBack()
      }}
    >
      <LeftIcon />
    </IconButton>
  )
}

const ContinueArrow = ({ enabled = true, text = defineMessage({ defaultMessage: "Continue" }), ...props }) => {
  const className = `
  flex flex-row no-underline dib animate-all
  ${enabled ? "pointer:hover dim" : "o-40"}
  `
  const intl = useIntl()
  return (
    <MaybeLink tabIndex={0} enabled={enabled} className={className} {...props}>
      <ArrowLinkArrow />
      <Spacer width={0.5} />
      <Body display="di" weight="fw5" color="black-80">
        {intl.formatMessage(text)}
      </Body>
    </MaybeLink>
  )
}

const Skip = (props) => (
  <MaybeLink tabIndex={0} className="black-80 dim" {...props}>
    <FormattedMessage defaultMessage="Skip" />
  </MaybeLink>
)

const SkipArrow = (props) => (
  <BodyArrow {...props}>
    <FormattedMessage defaultMessage="Skip" />
  </BodyArrow>
)

const BodyArrow = ({ children, ...other }) => {
  return (
    <ArrowLink {...other} TextComponent={Body} weight="fw5">
      {children}
    </ArrowLink>
  )
}

export {
  SwitchH1ToH2,
  SwitchH2ToBodyLarge,
  SpacerSwitcher,
  BackArrow,
  LeftArrow,
  ContinueArrow,
  Skip,
  SkipArrow,
  LeftBackArrowImage
}
