import React from "react"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "utils/Paths"
import { InternalIcon, Link } from "@carrotfertility/carotene-core"
import CardStates from "utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"

export function CarrotCardRequestedModule() {
  return (
    <CarrotCardModuleTemplate
      cardState={CardStates.PENDING}
      body={
        <FormattedMessage defaultMessage="We’re processing your request. You will receive an email when your virtual card is ready for use." />
      }
      links={[
        <Link
          key="carrot-card-how-to"
          endIcon={<InternalIcon fontSize="small" color="primary" />}
          href={DbgPaths.CARROT_CARD}
        >
          <FormattedMessage defaultMessage="How to use your Carrot Card" />
        </Link>
      ]}
    />
  )
}
