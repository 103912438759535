import { StatusCodes } from "http-status-codes"
// @ts-expect-error TS7016
import { v4 as uuid } from "uuid"
import ResponseError from "../../types/responseError"
import { reportError } from "../../utils/ErrorReporting"
import Settings from "../../utils/CarrotConfig"

export type ProviderCount = {
  hasFertilityProviders: boolean
  hasUrologyProviders: boolean
  hasAdoptionProviders: boolean
  hasCryobanksProviders: boolean
  hasLowTProviders: boolean
  hasMenopauseProviders: boolean
  hasGenderAffirmingHormoneTherapyProviders: boolean
  hasDonorAssistedReproductionProviders: boolean
  hasGestationalCarrierProviders: boolean
  hasProviders: boolean
}

export type HealthPlan = { healthPlanIdentifier: string }

export default class ProviderFinderClientError extends Error {
  readonly correlationId?: string

  constructor(message: string, { cause, correlationId }: { cause?: Error; correlationId?: string }) {
    super(message)
    this.name = this.constructor.name
    this.cause = cause
    this.correlationId = correlationId

    Object.setPrototypeOf(this, ProviderFinderClientError.prototype)
  }
}

export class ProviderFinderClient {
  baseUrl: string
  handleSessionExpiration: () => void

  constructor(baseUrl: string, handleSessionExpiration: () => void) {
    this.baseUrl = baseUrl
    this.handleSessionExpiration = handleSessionExpiration
  }

  async processJsonResponse<ResponseType>(response: Response, correlationId: string): Promise<ResponseType> {
    if (response.status === StatusCodes.OK) {
      return response?.json()
    }
    throw new ResponseError(response, correlationId)
  }

  createJsonHeaders(correlationId: string): Headers {
    const headers = new Headers()
    headers.append("X-Request-ID", correlationId)
    headers.append("X-React-App-Version", process.env.REACT_APP_VERSION || "local")
    headers.append("Content-Type", "application/json")
    headers.append("X-Authorizer-Domain", Settings.AUTHORIZER_DOMAIN)
    return headers
  }

  async get<ResponseType>(url: RequestInfo | URL): Promise<ResponseType> {
    const correlationId = uuid()

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: this.createJsonHeaders(correlationId),
        credentials: "include"
      })

      return await this.processJsonResponse(response, correlationId)
    } catch (error) {
      error.correlationId = correlationId

      if (error instanceof ResponseError && error.statusCode === StatusCodes.UNAUTHORIZED) {
        this.handleSessionExpiration()
        return null
      }

      reportError(
        new ProviderFinderClientError(`Failed to fetch ${url} `, {
          cause: error, // TODO Sentry groups this based on a custom grouping rule (src/utils/ErrorReporting.ts) with a wide net of anything with "Failed to fetch" in the stack trace.
          correlationId
        })
      )

      throw error
    }
  }

  async getHasProviderTypeInCountry(companyCountryCode: string): Promise<ProviderCount> {
    const url = new URL(`${this.baseUrl}/has-provider-type-in-country`)
    url.searchParams.set("companyCountryCode", companyCountryCode)

    return this.get(url)
  }
}
