import { FlexContainer, H3, Stack, Text, useBreakpointComponent } from "@carrotfertility/carotene"
import { LeftBackArrowImage } from "../views/atoms/Molecules"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import moment from "moment"
import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"
import React from "react"
import { HR } from "../views/atoms/Atoms"
import { FormattedMessage, useIntl } from "react-intl"
import { getPiiProps } from "services/tracking"

type BackArrowButtonProps = {
  onClick: () => void
}

const BackArrowButton = ({ onClick }: BackArrowButtonProps): JSX.Element => (
  <FlexContainer
    background="veryLightCarrotOrange"
    alignItems="center"
    justifyContent="center"
    height="2rem"
    width="2rem"
    borderRadius="100%"
    sx={{ cursor: "pointer" }}
    onClick={onClick}
  >
    <LeftBackArrowImage />
  </FlexContainer>
)

const Headers = (): JSX.Element => {
  const { showNewThreadForm, setShowNewThreadForm, setShowOnlyThreadsList, selectedThread } = useInAppMessaging()
  return (
    <FlexContainer direction="column" justifyContent="flex-end" width="100%" height={[false, null, "192px", null]}>
      <FlexContainer stack="medium" alignItems="center" sx={{ paddingBlockStart: ["29px", null, "18px", null] }}>
        <BackArrowButton
          onClick={() => {
            if (showNewThreadForm) {
              setShowNewThreadForm(false)
              setShowOnlyThreadsList(true)
            } else {
              setShowOnlyThreadsList(true)
            }
          }}
        />
        {showNewThreadForm ? (
          <FlexContainer height="100%" alignItems="end">
            <H3 marginLeft="12.47px">
              <FormattedMessage defaultMessage="Send a new message" />
            </H3>
          </FlexContainer>
        ) : (
          <>
            <Stack spacing="none" sx={{ marginInlineStart: "15.91px" }}>
              <Text
                variant="caps"
                color="black40"
                sx={{
                  fontWeight: "425",
                  fontSize: "11px",
                  letterSpacing: "0.06em"
                }}
              >
                {selectedThread.threadId !== -1 && moment(selectedThread.updatedAt).format("MMM D")}
              </Text>
              <H3 {...getPiiProps()}>{selectedThread.subject}</H3>
            </Stack>
          </>
        )}
      </FlexContainer>
      <HR color="black-20" />
    </FlexContainer>
  )
}

const MessagesHeader = (): JSX.Element => {
  const { showOnlyThreadsList } = useInAppMessaging()
  const intl = useIntl()
  const title = intl.formatMessage({ defaultMessage: "Messages", description: "contact us through messages" })
  const header = useBreakpointComponent([
    <Headers key="smallest" />,
    <Headers key="small" />,
    null,
    <PageHeader
      key="large"
      startGlyph={<PageHeaderGlyph src="/images/icn-talk-to-carrot.png" srcSet="/images/icn-talk-to-carrot-2x.png" />}
      pageTitle={title}
    />
  ])

  if (showOnlyThreadsList) {
    return (
      <PageHeader
        startGlyph={<PageHeaderGlyph src="/images/icn-talk-to-carrot.png" srcSet="/images/icn-talk-to-carrot-2x.png" />}
        pageTitle={title}
      />
    )
  }

  return header as JSX.Element
}

export { MessagesHeader }
