import React from "react"
import { FormRadioGroup, FormRadioGroupProps } from "@carrotfertility/carotene-core-x"
import { FormControlLabel, radioClasses, Radio } from "@carrotfertility/carotene-core"

export function FormRadioButtonGroup({ name, options, ...otherProps }: FormRadioGroupProps): JSX.Element {
  return (
    <FormRadioGroup
      {...{ name, options }}
      {...otherProps}
      renderItem={({ formControlLabelProps, radioProps }) => {
        return (
          <FormControlLabel
            key={radioProps.value as string}
            {...formControlLabelProps}
            sx={(theme) => ({
              padding: theme.spacing(theme.tokens.spacing.sm),
              borderRadius: theme.tokens.borderRadius.md,
              borderStyle: "solid",
              borderWidth: "2px",
              inlineSize: "fit-content",
              borderColor: radioProps.checked ? theme.palette.secondary.main : "transparent",
              [`:not(.${radioClasses.disabled}):hover`]: {
                bgcolor: theme.palette.secondary.light
              },
              [`&.${radioClasses.disabled}`]: {
                borderColor: radioProps.checked ? theme.palette.action.disabled : "transparent"
              }
            })}
            control={<Radio {...radioProps} />}
          />
        )
      }}
    />
  )
}
