import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { FormSingleSelect } from "components/views/atoms/forms/FormSingleSelect"
import { SelectProps } from "@carrotfertility/carotene"
import { useIntl } from "react-intl"
import { Box, Button, IconButton, LeftIcon, RightIcon } from "@carrotfertility/carotene-core"
import { FormRadioGroup } from "@carrotfertility/carotene-core-x"

interface BenefitEnrollmentFlowModalBackButtonProps {
  handleGoBack?: () => void
}
export function BenefitEnrollmentFlowModalBackButton({
  handleGoBack
}: BenefitEnrollmentFlowModalBackButtonProps): JSX.Element {
  const { gotoPreviousStep } = useStateMachine()
  const intl = useIntl()
  return (
    <IconButton
      size="medium"
      onClick={handleGoBack || gotoPreviousStep}
      aria-label={intl.formatMessage({ defaultMessage: "Back" })}
      color="secondary"
    >
      <LeftIcon color="secondary" />
    </IconButton>
  )
}

export function BenefitEnrollmentModalContinueButton({ onClick }: { onClick: () => void }): JSX.Element {
  const intl = useIntl()
  const continueText = intl.formatMessage({ defaultMessage: "Continue" })
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        size="medium"
        onClick={onClick}
        aria-label={continueText}
        sx={{ textWrap: "no-wrap" }}
        color="secondary"
        variant="outlined"
        endIcon={<RightIcon />}
      >
        {continueText}
      </Button>
    </Box>
  )
}

interface BenefitEnrollmentModalFormSelectProps extends Omit<SelectProps, "defaultValue"> {
  defaultValue?: string
}
export function BenefitEnrollmentModalFormSelect({
  defaultValue = null,
  name,
  options,
  ...otherProps
}: BenefitEnrollmentModalFormSelectProps): JSX.Element {
  const { setValue } = useFormContext()
  const value = defaultValue !== null ? String(defaultValue) : null
  const intl = useIntl()
  useEffect(() => {
    setValue(
      name,
      options.find(({ value: optionValue }) => String(optionValue) === value)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])
  return (
    <FormSingleSelect
      rules={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
      {...{ ...otherProps, name, options }}
    />
  )
}

interface BenefitEnrollmentModalFormRadioProps {
  name: string
  options: { label: string; value: string }[]
}
export function BenefitEnrollmentModalFormRadio({ name, options }: BenefitEnrollmentModalFormRadioProps): JSX.Element {
  const intl = useIntl()
  return (
    <FormRadioGroup
      name={name}
      options={options}
      controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
    />
  )
}

// eslint-disable-next-line import/no-restricted-paths
export { default as DateOfBirth } from "./DateOfBirthWithLabels"
